/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Vector: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="vector" ref={ref}  {...props}>
            <path
                d={isLarge ? "M225.716 366.0062C222.114 369.6052 219.912 374.6038 219.912 380.20228C219.912 391.1992 228.92 400.1966746 239.93 399.99673074C245.536 399.99673074 250.54 397.79734 254.144 394.19836L314.198 334.2152C317.802 330.6162 320.004 325.4176 320.004 320.0192C320.004 314.6206 317.802 309.6222 314.198 305.8232L254.144 245.84C250.54 242.241 245.536 240.0416 239.93 240.0416C228.92 240.0416 219.912 249.0392 219.912 260.036C219.912 265.6344000000001 222.314 270.6330000000001 225.918 274.232L251.742 300.0248000000001H100.0186C89.0084 300.0248000000001 80 309.0222 80 320.0192C80 331.016 89.0084 340.0136 100.0186 340.0136H251.742L225.716 366.0062zM111.056 197.89C120.9358 202.8288 132.9494 198.824 137.8894 188.944L200.002 64.722L262.1140000000001 188.944C267.0540000000001 198.824 279.068 202.8288 288.948 197.89C298.828 192.95 302.832 180.936 297.892 171.056L217.89 11.056C214.502 4.28 207.578 0 200.002 0C192.4264 0 185.501 4.28 182.113 11.056L102.1116 171.056C97.1718 180.936 101.1764 192.95 111.056 197.89z" : "M194.0574 314.2L234.008 274.2H234.208C237.802 270.6 240 265.6 240 260C240 254.4 237.802 249.4 234.208 245.8L194.2572 205.8C190.6616 202.2 185.668 200 180.075 200C169.0886 200 160.0998 209 160.0998 220C160.0998 225.6 162.2972 230.6 165.8926 234.2L171.6854 240H99.975C88.9888 240 80 249 80 260C80 271 88.9888 280 99.975 280H171.2858L165.4932 285.8C161.8976 289.4 159.7004 294.4 159.7004 300C159.7004 311 168.6892 320 179.8752 320C185.4682 320 190.462 317.8 194.0574 314.2zM191.4002 147.1998C195.4002 157.3998 207 162.5998 217.2 158.5998C227.4 154.5998 232.6 142.9998 228.6 132.7998L183.6002 16.8C179.6002 6.6 170.4002 0 160.0002 0C149.6002 0 140.4002 6.6 136.4002 16.8L91.4002 132.7998C87.4002 142.9998 92.4002 154.5998 102.8002 158.5998C113.0002 162.5998 124.6002 157.5998 128.6002 147.1998L160.0002 66.4L191.4002 147.1998z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Vector.defaultProps = {
    size: IconSize.STANDARD,
};
Vector.displayName = `Blueprint5.Icon.Vector`;
export default Vector;
