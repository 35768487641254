import { useEffect, useMemo, useState } from 'react'
import Lang from '../../../Lang'
import { DateTime } from 'luxon'
import { Popover2 } from '@blueprintjs/popover2'
import { Button, Menu, MenuItem } from '@blueprintjs/core'
import './PeriodPicker.scss'

import { DateRangePicker } from '@blueprintjs/datetime'

function getJSDateRange(periodKey) {
	const now = DateTime.now()

	switch (periodKey) {
		case 'today': {
			return [now.toJSDate(), now.toJSDate()]
		}
		case 'yesterday': {
			return [now.minus({ day: 1 }).toJSDate(), now.minus({ day: 1 }).toJSDate()]
		}
		case 'thisWeek': {
			return [now.startOf('week').toJSDate(), now.endOf('week').toJSDate()]
		}
		case 'lastWeek': {
			return [now.minus({ week: 1 }).startOf('week').toJSDate(), now.minus({ week: 1 }).endOf('week').toJSDate()]
		}
		case 'thisMonth': {
			return [now.startOf('month').toJSDate(), now.endOf('month').toJSDate()]
		}
		case 'lastMonth': {
			return [now.minus({ month: 1 }).startOf('month').toJSDate(), now.minus({ month: 1 }).endOf('month').toJSDate()]
		}
		case 'last3Months': {
			return [now.minus({ month: 3 }).startOf('month').toJSDate(), now.toJSDate()]
		}
		default: {
			return [now.toJSDate(), now.toJSDate()]
		}
	}
}

const periods = {
	today: Lang.get('Today'),
	yesterday: Lang.get('Yesterday'),
	thisWeek: Lang.get('This week'),
	lastWeek: Lang.get('Last week'),
	thisMonth: Lang.get('This month'),
	lastMonth: Lang.get('Last month'),
	last3Months: Lang.get('Last 3 months')
}

export default function PeriodPicker({ onChange }) {
	const [range, setRange] = useState([new Date(), new Date()])

	useEffect(() => {
		if (range.every((d) => d != null)) onChange([DateTime.fromJSDate(range[0]), DateTime.fromJSDate(range[1])])
	}, [range])

	const shortcuts = useMemo(() => {
		return Object.keys(periods).map((periodKey) => {
			return {
				label: periods[periodKey],
				dateRange: getJSDateRange(periodKey)
			}
		})
	}, [])

	return (
		<div className="periodPicker">
			<div>
				<Popover2
					content={
						<Menu>
							<DateRangePicker
								highlightCurrentDay={true}
								allowSingleDayRange={true}
								value={range}
								onChange={(r) => setRange(r)}
								formatDate={(a) => a}
								parseDate={() => null}
								shortcuts={shortcuts}
								dayPickerProps={{ firstDayOfWeek: 1 }}
							/>
						</Menu>
					}
					position="bottom-left">
					<Button text={Lang.get('Period')} icon="time" rightIcon="caret-down" />
				</Popover2>
			</div>
		</div>
	)
}
