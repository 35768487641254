/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const BringForward: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="bring-forward" ref={ref}  {...props}>
            <path
                d={isLarge ? "M185.8578 394.14214C193.6684 401.952622 206.332 401.952622 214.142 394.14214L294.142 314.1422C301.952 306.3316 301.952 293.6684 294.142 285.8578C286.332 278.0474000000001 273.668 278.0474000000001 265.858 285.8578L220 331.7158V220H380C391.046 220 400 211.0458 400 200C400 188.954 391.046 180 380 180H220V120H380C391.046 120 400 111.046 400 100V40C400 28.954 391.046 20 380 20H20C8.95432 20 0 28.954 0 40V100C0 111.046 8.9543 120 20 120H180V180H20C8.95432 180 0 188.954 0 200C0 211.0458 8.9543 220 20 220H180V331.7158L134.1422 285.8578C126.3316 278.0474000000001 113.6684 278.0474000000001 105.8578 285.8578C98.0474 293.6684 98.0474 306.3316 105.8578 314.1422L185.8578 394.14214z" : "M173.9412 314.2C170.3436 317.8 165.3466 320 159.95 320C154.5534 320 149.3566 317.8 145.7588 314.2L85.7964 254.4C82.1986 250.6 80 245.6 80 240.2C80 229.2 88.9944 220.2 99.9876 220.2C105.584 220.2 110.5808 222.4 114.1786 226L139.9626 251.8C139.9626 227.8668 140 203.9338 140 180.0006H20C8.9543 180.0006 0 171.0462 0 160.0006C0 148.9548 8.9543 140.0006 20 140.0006H140V100H20C8.9543 100 0 91.046 0 80V40C0 28.954 8.9543 20 20 20H300C311.046 20 320 28.954 320 40V80C320 91.046 311.046 100 300 100H180V140.0006H300C311.046 140.0006 320 148.9548 320 160.0006C320 171.0462 311.046 180.0006 300 180.0006H180L180 251.7162H179.9376V251.8L205.722 226C209.32 222.4 214.316 220.2 219.712 220.2C230.706 220.2 239.7 229.2 239.7 240.2C239.7 245.8 237.502 250.8 233.904 254.4L173.9412 314.2z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
BringForward.defaultProps = {
    size: IconSize.STANDARD,
};
BringForward.displayName = `Blueprint5.Icon.BringForward`;
export default BringForward;
