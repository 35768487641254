/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Rectangle: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="rectangle" ref={ref}  {...props}>
            <path
                d={isLarge ? "M20 320H380C391 320 400 311 400 300V100C400 89 391 80 380 80H20C9 80 0 89 0 100V300C0 311 9 320 20 320zM40 280V120H360V280H40z" : "M20 260H300C311 260 320 251 320 240V80C320 69 311 60 300 60H20C9 60 0 69 0 80V240C0 251 9 260 20 260zM40 220V100H280V220H40z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Rectangle.defaultProps = {
    size: IconSize.STANDARD,
};
Rectangle.displayName = `Blueprint5.Icon.Rectangle`;
export default Rectangle;
