import React from 'react'
import Lang from '../Lang'
import { DateTime } from 'luxon'

export default function Time(props) {
	let date = null

	if (props.timestamp) {
		date = DateTime.fromSeconds(props.timestamp)
	} else if (props.iso) {
		date = DateTime.fromISO(props.iso)
	}

	let ret = Lang.get('Unknown')

	if (date) {
		ret = date.toFormat('dd.LL.yy HH:mm')
	}

	return ret
}
