import './Placeholder.scss'

import React from 'react'
import { Spinner } from '@blueprintjs/core'

export default class Placeholder extends React.Component {
	render() {
		return (
			<div className={`Placeholder ${this.props.className}`}>
				{this.props.icon && (
					<div className="icon">
						<i className={this.props.icon} />
					</div>
				)}
				{this.props.loading && <Spinner />}
				{this.props.label && <div className="label">{this.props.label}</div>}
			</div>
		)
	}
}
