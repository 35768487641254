/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const CloudTick: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="cloud-tick" ref={ref}  {...props}>
            <path
                d={isLarge ? "M295.68 319.568C294.7200000000001 319.424 293.76 319.28 292.8 319.2C276.6 366.2 232.4 400 180 400C113.8 400 60 346.2 60 280C60 279.5 60.05 279.05 60.1 278.6C60.15 278.15 60.2 277.7 60.2 277.2C25.6 268.4 0 237.4 0 200C0 155.8 35.8 120 80 120H103.4166C106.3048 128.19 111.0238 135.876 117.5736 142.426C141.005 165.858 178.995 165.858 202.426 142.426L220 124.852L317.574 222.4264C340.1760000000001 245.0286 376.324 245.8284 399.886 224.8258C397.3640000000001 277.7876 353.582 320 300 320C298.56 320 297.12 319.784 295.68 319.568zM234.142 25.858C226.332 18.048 213.668 18.048 205.858 25.858L145.8578 85.858C138.0474 93.668 138.0474 106.332 145.8578 114.142C153.6684 121.952 166.3316 121.952 174.1422 114.142L220 68.284L345.854 194.14C352.412 200.696 362.392 201.7512 370.0460000000001 197.3C371.5100000000001 196.448 372.8880000000001 195.396 374.142 194.142C380.976 187.308 381.83 176.758 376.704 168.996C375.972 167.886 375.118 166.834 374.142 165.858L234.142 25.858z" : "M238 239.8C238.6 240 239.4 240 240 240C278.294 240 310.282 213.128 318.148 177.2026C297.476 183.7416 273.964 178.8164 257.574 162.4264L200 104.852L182.4264 122.4264C158.995 145.8578 121.005 145.8578 97.5736 122.4264C85.8578 110.71 80 95.356 80 80H70C31.4 80 0 111.4 0 150C0 178 16.6 202.2 40.4 213.4C40.2 215.6 40 217.8 40 220C40 275.2 84.8 320 140 320C188.4 320 228.8 285.6 238 239.8zM315.294 132.8896C321.932 125.0316 321.548 113.264 314.142 105.858L214.142 5.858C206.332 -1.952 193.6684 -1.952 185.8578 5.858L125.8578 65.858C118.6986 73.018 118.1018 84.254 124.067 92.092C124.6096 92.806 125.2066 93.49 125.8578 94.142C133.6684 101.952 146.3316 101.952 154.1422 94.142L200 48.284L285.852 134.1356C293.662 141.946 306.332 141.9526 314.142 134.1422C314.548 133.7372 314.93 133.3192 315.294 132.8896z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
CloudTick.defaultProps = {
    size: IconSize.STANDARD,
};
CloudTick.displayName = `Blueprint5.Icon.CloudTick`;
export default CloudTick;
