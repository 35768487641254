import './index.scss'

import React from 'react'
import Lang from '../../Lang'
import Page from '../../Components/Page'
import { TimezoneSelect } from '@blueprintjs/datetime2'
import { Button, InputGroup, FormGroup } from '@blueprintjs/core'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			id: window.App.account.id,
			name: window.App.account.name,
			timeZone: window.App.account.timeZone,
			busy: false
		}
	}

	async save() {
		this.setState({ busy: true })
		await API.call('EditAccount', { id: this.state.id, name: this.state.name, timeZone: this.state.timeZone })
		location.reload()
	}

	render() {
		return (
			<Page title={Lang.get('Settings')} icon="fa-duotone fa-gear" className="SettingsPage">
				<FormGroup label={Lang.get('Account Name')}>
					<InputGroup value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
				</FormGroup>
				<FormGroup label={Lang.get('Time Zone')}>
					<TimezoneSelect showLocalTimezone value={this.state.timeZone} onChange={(tz) => this.setState({ timeZone: tz })} />
				</FormGroup>
				<Button intent="primary" disabled={this.state.busy} icon="tick" text={Lang.get('Save')} onClick={() => this.save()} />
			</Page>
		)
	}
}
