import React from 'react'
import Lang from '../Lang'
import { Popover2 } from '@blueprintjs/popover2'
import { Menu, MenuItem } from '@blueprintjs/core'
import FLAGS from './Flags'

export default function LanguageSelector(props) {
	let languageMenu = (
		<Menu>
			{Object.keys(Lang.availableLanguages).map((key) => {
				const language = Lang.availableLanguages[key]
				return (
					<MenuItem
						icon={FLAGS[key] || 'translate'}
						intent={Lang.lang === key ? 'primary' : 'none'}
						text={language.name}
						key={`lang-item-${key}`}
						onClick={() => {
							Lang.lang = key
							location.reload()
						}}
					/>
				)
			})}
		</Menu>
	)
	return (
		<div className="LanguageSelector">
			<Popover2 content={languageMenu}>
				<div>
					<i className="fa-fw fa-solid fa-globe" />
					<i className="fa-fw fa-solid fa-caret-down" />
				</div>
			</Popover2>
		</div>
	)
}
