import './StatsChart.scss'

import { useEffect, useRef, useState } from 'react'
import { useChartGroupContext } from './ChartGroup'
import { addEmptinessPoints } from '../../../../../Util'

import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'

import { Spinner } from '@blueprintjs/core'
import { DateTime } from 'luxon'
import Lang from '../../../../../Lang'

export default function StatsChart({ label, data, displayKey, loading }) {
	const { curDate } = useChartGroupContext()

	const [empty, setEmpty] = useState(false)

	const chartRef = useRef(null)
	const chartCanvasRef = useRef(null)

	useEffect(() => {
		if (!data) return

		// izfiltree to, kas nav tagad
		data = data.filter((stat) => stat.time.toFormat('yyyy-LL-dd') == curDate.toFormat('yyyy-LL-dd'))
		setEmpty(data.length === 0)

		data = addEmptinessPoints(data, displayKey)

		// lai izstretchotu pa visu, pieliek sakuma un end label, un pectam vel null punktus, kas ir neredzami
		// labels.length === points.length !
		const labels = [curDate.startOf('day'), ...data.map((row) => row.time), curDate.endOf('day')]
		const points = [null, ...data.map((row) => row[displayKey]), null]

		if (chartRef.current) {
			chartRef.current.data.labels = labels
			chartRef.current.data.datasets[0].data = points
			chartRef.current.update()
			return
		}

		chartRef.current = new Chart(chartCanvasRef.current, {
			type: 'line',
			options: {
				responsive: true,
				animation: {
					duration: 0
				},
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						usePointStyle: true,
						callbacks: {
							title: (context) => {
								const t = DateTime.fromMillis(context[0].parsed.x)
								return t.toFormat('HH:mm')
							}
						}
					}
				},
				scales: {
					y: {
						min: 0
					},
					x: {
						type: 'time',
						time: {
							unit: 'hour',
							displayFormats: {
								hour: 'HH:mm'
							}
						}
					}
				}
			},
			data: {
				labels: labels,
				datasets: [
					{
						label: label,
						data: points,
						backgroundColor: '#67CC7177',
						fill: true,
						borderColor: '#67CC71',
						inflateAmount: 1
					}
				]
			}
		})
	}, [curDate, data, displayKey])

	return (
		<div className="statsChart">
			<canvas className="chart" ref={chartCanvasRef} />
			{loading && <Spinner className="spinner" />}
			{!loading && empty && <span className="no-data">{Lang.get('No data')}</span>}
		</div>
	)
}
