/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Cube: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="cube" ref={ref}  {...props}>
            <path
                d={isLarge ? "M39.0633182 310.3789524L187.2421416 390.78281158C195.147553 395.07239614 204.852446 395.07239614 212.757858 390.78281158L360.936682 310.3789524L200 215.6051288L39.0633182 310.3789524zM22.7192898 296.7935118L190 198.28376L190 12.650586C189.0610178 13.027104 188.1399744 13.45895 187.2421416 13.9461260000001L32.9564272 97.663668C24.9444988 102.01105 20 110.101668 20 118.864096L20 285.8648418C20 289.7257424 20.9599542 293.4562136 22.7192898 296.7935118zM377.28071 296.7935118C379.040046 293.4562136 380 289.7257424 380 285.8648418L380 118.864096C380 110.101668 375.0555020000001 102.01105 367.043572 97.663668L212.757858 13.9461260000001C211.860026 13.45895 210.938982 13.027104 210 12.650586L210 198.28376L377.28071 296.7935118z" : "M283.874946 249.214317L160 171.7924764L36.125055 249.214317L150.0772212 314.3298407C156.2258746 317.84335692 163.7741254 317.84335692 169.9227788 314.3298407L283.874946 249.214317zM298.87596 235.0049984C299.609578 232.911297 300 230.680355 300 228.3935492L300 91.60645C300 84.429314 296.154278 77.802444 289.922778 74.241588L170 5.714286L170 154.4575236L298.87596 235.0049984zM21.1240402 235.0049984L150 154.4575236L150 5.714286L30.0772212 74.241588C23.8457212 77.802444 20 84.429314 20 91.60645L20 228.3935492C20 230.680355 20.3904216 232.911297 21.1240402 235.0049984z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Cube.defaultProps = {
    size: IconSize.STANDARD,
};
Cube.displayName = `Blueprint5.Icon.Cube`;
export default Cube;
