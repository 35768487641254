import { Buffer } from 'buffer'

// lokaali: uztaisi .env failu root dir -> ieliec tur REACT_APP_USE_LOCAL_API=1
// ja .env neeksiste tad vins nems prod api
const LOCAL_API = process.env.REACT_APP_USE_LOCAL_API == '1'

// ===================================================================

export enum RFCommand {
	NOOP = 0,
	ACK = 1,
	NACK = 2,
	SCAN = 3,
	SCAN_REPORT = 4,
	SET_IDENTITY = 5,
	FIRMWARE_UPDATE = 6,
	DEBUG_MSG = 7,
	IDENTIFY = 8,
	MOTION = 9,
	GET_SETTING = 10,
	SET_SETTING = 11,
	DALI_COMMAND = 12,
	BEACON = 13,
	DAYTIME = 14,
	RF_MODE = 15,
	CONFIG_WRITE = 16,
	CONFIG_READ = 17,
	CONFIG_REPORT = 18,
	PEER_UPDATE = 19,
	GET_ENERGY = 20,
	ENERGY_REPORT = 21,
	PEER_UPDATE_GET_PROGRESS = 22,
	PEER_UPDATE_PROGRESS_REPORT = 23,
	RELAY_TEST = 25,
	GPS = 26,
	MODBUS = 27,
	D4I = 28
}

export class RFPacket {
	public timestamp: number | undefined = undefined
	public gatewayId: number | undefined = undefined

	public rssi: number = 0
	public signature: number = 0x00069000
	public sourceId: number = 0
	public destinationId: number = 0
	public command: RFCommand = RFCommand.NOOP
	public commandName: string | null = null
	public data: Buffer = Buffer.alloc(0)
	public valid: boolean = true
	public raw: Buffer | null = null

	public scanReport: any = undefined
	public motion: any = undefined
	public config: any = undefined
	public energyReport: any = undefined
	public peerUpdateProgressReport: any = undefined

	toRaw() {
		const header = Buffer.alloc(13)
		header.writeUInt32LE(this.signature, 0)
		header.writeUInt32LE(this.sourceId, 4)
		header.writeUInt32LE(this.destinationId, 8)
		header.writeUInt8(this.command, 12)
		return Buffer.concat([header, this.data])
	}

	static fromRaw(packetData) {
		const packet = new RFPacket()
		packet.raw = packetData
		packet.valid = false

		if (packetData.length < 4) {
			return packet
		}

		const signature = packetData.readUInt32LE(0)
		if (signature != 0x00069000) {
			return packet
		}
		packet.signature = signature
		packet.sourceId = packetData.readUInt32LE(4)
		packet.destinationId = packetData.readUInt32LE(8)
		packet.command = packetData.readUInt8(12)
		packet.data = packetData.slice(13)

		packet.valid = true

		if (packet.command == RFCommand.SCAN_REPORT) {
			const scanReport: any = {}
			scanReport.sourceRssi = packet.data.readInt16LE(0)
			scanReport.operationMode = packet.data.readUInt8(2)
			scanReport.serialNr = packet.data.slice(3, 19).toString('hex')
			scanReport.firmwareVersion = packet.data.readUInt16LE(19)
			scanReport.deviceId = packet.data.readUInt32LE(21)
			scanReport.streetId = packet.data.readUInt32LE(25)
			scanReport.poleId = packet.data.readUInt32LE(29)
			packet.scanReport = scanReport
		} else if (packet.command == RFCommand.MOTION) {
			const motion: any = {}
			motion.streetId = packet.data.readUInt32LE(0)
			motion.poleId = packet.data.readUInt32LE(4)
			motion.motionAgo = packet.data.readUInt32LE(8)
			motion.motionSide = packet.data.readUInt8(12)
			packet.motion = motion
		} else if (packet.command == RFCommand.CONFIG_REPORT) {
			const config: any = {}
			config.operationMode = packet.data.readUInt8(0)
			config.daliSpeed = packet.data.readUInt8(1)
			config.idleBrightness = packet.data.readUInt8(2)
			config.activeTime = packet.data.readUInt16LE(3)
			config.activeBrightness = packet.data.readUInt8(5)
			config.activeBrightnessN1 = packet.data.readUInt8(6)
			config.activeBrightnessN2 = packet.data.readUInt8(7)
			config.activeBrightnessN3 = packet.data.readUInt8(8)
			config.activeBrightnessN4 = packet.data.readUInt8(9)
			config.activeBrightnessN5 = packet.data.readUInt8(10)
			packet.config = config
		} else if (packet.command == RFCommand.ENERGY_REPORT) {
			const energyReport: any = {}
			energyReport.consumption = packet.data.readFloatLE(0)
			packet.energyReport = energyReport
		} else if (packet.command == RFCommand.PEER_UPDATE_PROGRESS_REPORT) {
			const peerUpdateProgressReport: any = {}
			peerUpdateProgressReport.progress = packet.data.readFloatLE(0)
			packet.peerUpdateProgressReport = peerUpdateProgressReport
		}

		for (let name in RFCommand) {
			var n = parseInt(RFCommand[name], 10)
			if (n == packet.command) {
				packet.commandName = name
			}
		}

		if (packet.commandName == null) {
			packet.commandName = 'UNKNOWN'
		}

		return packet
	}
}

// ===================================================================

class BaseAPI {
	public url: string
	public isConnected: boolean = false
	public socket: WebSocket
	public reconnectTimer: any = null
	public listeners: any = []
	public nextCallId: number = 1
	public callResolves: any = {}

	constructor(url: string) {
		this.url = url
		this.connect()
	}

	call(method: string, args: any = {}): Promise<any> {
		if (window.location.href.indexOf('localhost') > -1) {
			console.log('☎️ API [', method, ']', args)
		}
		return new Promise((resolve, reject) => {
			if (this.socket.readyState === WebSocket.OPEN) {
				this.socket.send(JSON.stringify({ cmd: method, args, callbackId: this.nextCallId }))
				this.callResolves[this.nextCallId] = resolve
				this.nextCallId++
				return
			}

			reject('no connection')
			this.connect()
		})
	}

	addConnectionListener(owner: any, callback: (connected: boolean) => void) {
		this.listeners.push({ type: 'connection', owner, callback })
	}

	addMessageListener(owner: any, method: string, callback: any) {
		this.listeners.push({ type: 'message', owner, method, callback })
	}

	removeListener(owner: any) {
		this.listeners = this.listeners.filter((l) => l.owner != owner)
	}

	connect() {
		if (this.isConnected) {
			return
		}
		clearTimeout(this.reconnectTimer)

		this.socket = new WebSocket(this.url)

		this.socket.onopen = () => {
			this.isConnected = true

			for (let l of this.listeners) {
				if (l.type == 'connection') {
					l.callback(true)
				}
			}
		}

		this.socket.onmessage = (data) => {
			const parsed = JSON.parse(data.data)
			if (parsed.cmd == '_CALLBACK_') {
				this.callResolves[parsed.callbackId](parsed.args)
				delete this.callResolves[parsed.callbackId]
			} else {
				for (let l of this.listeners) {
					if (l.type == 'message') {
						if (parsed.cmd == l.method) {
							const out = { ...parsed }
							delete out.cmd
							l.callback(out)
						}
					}
				}
			}
		}

		this.socket.onclose = () => {
			if (this.isConnected) {
				this.isConnected = false
				for (let l of this.listeners) {
					if (l.type == 'connection') {
						l.callback(false)
					}
				}
			}
		}

		this.socket.onerror = () => {
			clearTimeout(this.reconnectTimer)
			this.reconnectTimer = setTimeout(() => this.connect(), 1000)
		}
	}

	disconnect() {
		clearTimeout(this.reconnectTimer)
		this.socket.close()
	}
}

// ===================================================================

class API_ extends BaseAPI {
	public connections: any = []
	public packetWaiters: any = []

	constructor() {
		console.log(LOCAL_API)

		const url = LOCAL_API ? 'ws://localhost:8001' : 'wss://api.idealights.lv'
		super(url)

		this.addMessageListener(this, 'agents.raw-command', (data) => {
			if (data.type == 50 && data.direction == 'in') {
				const raw = Buffer.from(data.payloadHex.substr(4), 'hex')
				const p = RFPacket.fromRaw(raw)
				p.rssi = Buffer.from(data.payloadHex.substr(0, 4), 'hex').readInt16LE(0)
				for (let l of this.listeners) {
					if (l.type == 'rf') {
						if (l.rfCommand == null || l.rfCommand == p.command) {
							p.gatewayId = this.getDeviceIdForConnectionId(data.agentId)
							p.timestamp = data.time
							l.callback(p)
						}
					}
				}
			}
		})

		this.connections = []
		this.packetWaiters = []
		setInterval(() => this.processWaiters(), 10)

		this.addMessageListener(this, 'agent-list', (data) => {
			this.connections = data.args
		})

		this.addMessageListener(this, 'agents.raw-command', (data) => {
			if (data.type == 50 && data.direction == 'in') {
				const raw = Buffer.from(data.payloadHex.substr(4), 'hex')
				const p = RFPacket.fromRaw(raw)
				p.rssi = Buffer.from(data.payloadHex.substr(0, 4), 'hex').readInt16LE(0)
				this.packetWaiters = this.packetWaiters.filter((w) => {
					if (w.config.deviceId == p.sourceId && w.config.command == p.command) {
						w.resolve(p)
						return false
					}
					return true
				})
			}
		})

		setInterval(() => {
			this.call('agents.list')
		}, 1500)
	}

	getDeviceIdForConnectionId(connectionId) {
		for (let con of this.connections) {
			if (con.id == connectionId) {
				if (con.scanReport) {
					return con.scanReport.deviceId
				}
			}
		}
	}

	addRFListener(owner: any, rfCommand: RFCommand, callback: (packet: RFPacket) => void) {
		this.listeners.push({ type: 'rf', owner, rfCommand, callback })
	}

	getDevices() {
		return this.call('Device.List')
	}

	processWaiters() {
		const now = new Date().getTime()
		this.packetWaiters = this.packetWaiters.filter((w) => {
			if (w.created + w.config.timeout < now) {
				w.resolve(null)
				return false
			}
			return true
		})
	}

	waitPacket(config) {
		config = { timeout: 2000, ...config }
		return new Promise((resolve, reject) => {
			this.packetWaiters.push({ created: new Date().getTime(), config, resolve, reject })
		})
	}

	async sendPacketAndWaitResponse(packet, device, responseConfig, preferGateway = -1) {
		for (let i = 0; i < 5; i++) {
			if (i > 0) {
				console.log('Retry #' + i)
			}
			this.sendPacket(packet, device, preferGateway)
			const res = await this.waitPacket(responseConfig)
			if (res) {
				return res
			}
		}
		return null
	}

	sendPacket(packet, device, preferredGatewayConId = -1) {
		if (device.broadcast) {
			packet.destinationId = 0
		} else {
			packet.destinationId = device.id
		}

		let targetConnection: any = null
		if (preferredGatewayConId == -1) {
			// Check if GSM directly connected
			targetConnection = this.getConnectionForSerialNr(device.serialNr)

			// Look for exit node
			if (!targetConnection) {
				if (device.gateways.length > 0) {
					for (let n of device.gateways) {
						targetConnection = this.getConnectionForSerialNr(n.serialNr)
						if (targetConnection) {
							break
						}
					}
				}
			}
		} else {
			targetConnection = {
				id: preferredGatewayConId
			}
		}

		// Send it
		if (targetConnection) {
			const hex = packet.toRaw().toString('hex')
			this.call('agents.raw-command', {
				agentId: targetConnection.id,
				type: 50,
				payloadHex: hex
			}).then(() => {})
		} else {
			console.error('No exit node for', device.serialNr, device.id)
		}
	}

	getConnectionForSerialNr(serialNr): any {
		let targetConnection = null
		for (let conn of this.connections) {
			if (conn.scanReport && serialNr == conn.scanReport.serialNr) {
				targetConnection = conn
			}
		}
		return targetConnection
	}
}

// ===================================================================

const API = new API_()
export default API

// ===================================================================
