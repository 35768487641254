import './QRDialog.scss'

import { Dialog, DialogBody } from '@blueprintjs/core'
import { useEffect, useRef, useState } from 'react'

import QrScanner from '../Lib/QRScanner.min.js'

const QR_STATES = {
	NO_CAMERA: -1,
	PENDING_CAMERA: 0,
	SCANNING: 1,
	FOUND: 2
}

export default function QRDialog({ open, setOpen, onScan }) {
	const [state, setState] = useState(QR_STATES.PENDING_CAMERA)
	const scannerRef = useRef(null)

	useEffect(() => {
		if (open) {
			setState(QR_STATES.PENDING_CAMERA)
			if (!scannerRef.current) {
				setTimeout(async () => {
					const el = document.getElementById('qr-preview')
					if (!el) return

					if (!(await QrScanner.hasCamera())) {
						setState(QR_STATES.NO_CAMERA)
						return
					}

					scannerRef.current = new QrScanner(
						el,
						(result) => {
							onScan(result.data)
							setState(QR_STATES.FOUND)
							setOpen(false)
						},
						{ returnDetailedScanResult: true }
					)
					scannerRef.current.start()
					setState(QR_STATES.SCANNING)
				}, 200)
			}
		} else {
			if (scannerRef.current) {
				scannerRef.current.stop()
				scannerRef.current = null
			}
		}

		return () => {
			if (scannerRef.current) {
				scannerRef.current.stop()
				scannerRef.current = null
			}
		}
	}, [open])

	return (
		<Dialog isOpen={open} onClose={() => setOpen(false)} className="qr-dialog">
			<DialogBody>
				<p className="status">
					{(() => {
						switch (state) {
							case QR_STATES.NO_CAMERA:
								return 'No camera found'
							case QR_STATES.PENDING_CAMERA:
								return 'Loading camera...'
							case QR_STATES.SCANNING:
								return 'Scanning...'
							case QR_STATES.FOUND:
								return 'Found!'
						}
					})()}
				</p>
				<video id="qr-preview" style={{ ...(!open && { display: 'none' }) }}></video>
			</DialogBody>
		</Dialog>
	)
}
