import './ConfirmPopover.scss'

import React from 'react'
import Lang from '../Lang'
import { Button } from '@blueprintjs/core'
import { Classes, Popover2 } from '@blueprintjs/popover2'

export default class ConfirmPopover extends React.Component {
	confirm() {
		if (this.props.onConfirm) {
			this.props.onConfirm()
		}
	}

	render() {
		const message = this.props.message || Lang.get('Are you sure?')
		const confirmTitle = this.props.confirmTitle || Lang.get('Confirm')
		return (
			<Popover2
				content={
					<div className="ConfirmPopover">
						<div className="message">{message}</div>
						<div className="buttons">
							<Button text={Lang.get('Cancel')} className={Classes.POPOVER2_DISMISS} />
							<Button text={confirmTitle} intent="danger" className={Classes.POPOVER2_DISMISS} onClick={() => this.confirm()} />
						</div>
					</div>
				}>
				{this.props.children}
			</Popover2>
		)
	}
}
