/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Input: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="input" ref={ref}  {...props}>
            <path
                d={isLarge ? "M400 200C400 89.8 310.2 0 200 0C176.6 0 153.6 4 131.6 12C121.2 15.8 115.8 27.2 119.6 37.6C123.4 48 134.8 53.4 145.2 49.6C162.8 43.2 181.2 40 200 40C288.2 40 360 111.8 360 200C360 288.2 288.2 360 200 360C181.2 360 162.8 356.8 145.2 350.4C134.8 346.6 123.4 352 119.6 362.4C115.8 372.8 121.2 384.2 131.6 388C153.6 396 176.6 400 200 400C310.2 400 400 310.2 400 200zM314.2000000000001 214.2L234.2 294.2C230.6 297.8 225.6 300 220 300C209 300 200 291 200 280C200 274.4 202.2 269.4 205.8 265.8L251.8 220H20C8.9543 220 0 211.0456 0 200C0 188.954 8.9543 180 20 180H251.8L206 134.2000000000001C202.2 130.6 200 125.6 200 120C200 109 209 100 220 100C225.6 100 230.6 102.2 234.2 105.8L314.2000000000001 185.8C317.8 189.4 320 194.4 320 200C320 205.6 317.8 210.6 314.2000000000001 214.2z" : "M160 0C248.2 0 320 71.644 320 160.1C320 248.5554 248.2 320 160 320C141.2 320 122.8 316.798 105.2 310.394C94.8 306.59162 89.4 295.1844 93.2 284.778C97 274.3714 108.4 268.9682 118.8 272.7704C132 277.5734 145.8 279.975 159.8 279.975C226 279.975 279.8 226.1414 279.8 159.9C279.8 93.658 226 39.824 159.8 39.824C145.8 39.824 132 42.226 118.8 47.03C108.4 50.832 97 45.428 93.2 35.022C89.4 24.616 94.8 13.208 105.2 9.406C122.8 3.002 141.2 0 160 0zM234.2 174.1088C237.8 170.5066 240 165.5034 240 159.9C240 154.2964 237.8 149.2934 234.2 145.691L174.2 85.654C170.6 82.052 165.6 79.85 160 79.85C149 79.85 140 88.856 140 99.862C140 105.466 142.2 110.47 146 114.072L171.8 139.8874H20C9 139.8874 0 148.893 0 159.9C0 170.9068 9 179.9124 20 179.9124H171.8L145.8 205.7286C142.2 209.3308 140 214.334 140 219.9374C140 230.9444 149 239.95 160 239.95C165.6 239.95 170.6 237.7486 174.2 234.1464L234.2 174.1088z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Input.defaultProps = {
    size: IconSize.STANDARD,
};
Input.displayName = `Blueprint5.Icon.Input`;
export default Input;
