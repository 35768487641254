import './index.scss'

import React from 'react'
import { DateTime } from 'luxon'
import Page from '../../../Components/Page'
import API, { RFCommand, RFPacket } from '../../../API'
import { Button, FormGroup, H5, InputGroup } from '@blueprintjs/core'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			packets: []
		}
	}

	componentDidMount() {
		this.reload()
	}

	async reload() {
		if (this.state.busy) return
		this.setState({ busy: true })
		const query = {}
		if (this.state.packets.length > 0) {
			query.afterId = this.state.packets[0].id
		}
		let res = await API.call('RF.Query', query)
		const newPackets = [...res, ...this.state.packets]
		if (newPackets.length > 100) {
			newPackets.splice(100, newPackets.length - 100)
		}
		this.setState({ busy: false, packets: newPackets })
		setTimeout(() => this.reload(), 500)
	}

	clear() {
		this.setState({ packets: [] })
	}

	formatId(id) {
		if (id == 0) return 'BROADCAST'
		if (id == 4000000000) return 'SERVER'
		return id
	}

	render() {
		return (
			<Page title="Admin RF Traffic" className="AdminRFTrafficPage" icon="fa-duotone fa-satellite-dish">
				<div className="RFLog">
					<H5>
						Live Traffic <Button small minimal icon="trash" onClick={() => this.clear()} />
					</H5>
					<table className="bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped">
						<thead>
							<tr>
								<th width="85">Time</th>
								<th width="40">Dir.</th>
								<th width="70">Gateway</th>
								<th width="50">RSSI</th>
								<th width="70">From</th>
								<th width="70">To</th>
								<th width="250">Command</th>
								<th>Payload</th>
							</tr>
						</thead>
						<tbody>
							{this.state.packets.map((p) => {
								const hex = p.payloadHex.match(/.{1,2}/g).join(' ')
								return (
									<tr key={p.id}>
										<td>{DateTime.fromISO(p.time).toFormat('HH:mm:ss')}</td>
										<td>{p.direction}</td>
										<td>{p.gatewayId}</td>
										<td>{p.rssi != 0 ? p.rssi : ''}</td>
										<td>{this.formatId(p.sourceId)}</td>
										<td>{this.formatId(p.destinationId)}</td>
										<td className="colCommand">
											{p.commandName} <small>#{p.commandType}</small>
										</td>
										<td className="colPayload">
											<div className="payloadHex">{hex}</div>
											{p.payload && <div className="payloadJson">{JSON.stringify(p.payload)}</div>}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</Page>
		)
	}
}
