import { Menu, MenuItem } from '@blueprintjs/core'
import API from '../API'
import { Popover2 } from '@blueprintjs/popover2'

export default function AccountSelector() {
	const menu = (
		<Menu className="scrollable">
			{window.App.accounts.map((account) => (
				<MenuItem
					icon="map-marker"
					text={account.name}
					key={`acc-${account.name}`}
					intent={window.App.account.name == account.name ? 'primary' : 'none'}
					onClick={() => {
						API.call('SetAccount', { id: account.id }).then(() => {
							location.reload()
						})
					}}
				/>
			))}
		</Menu>
	)

	return (
		<Popover2 content={menu} position="bottom">
			<div className="switchable">
				<i className="fa-fw fa-solid fa-location-dot" />
				{window.App.account.name}
				<i className="switcher fa-fw fa-solid fa-caret-down" />
			</div>
		</Popover2>
	)
}
