/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const SoccerBall: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="soccer-ball" ref={ref}  {...props}>
            <path
                d={isLarge ? "M190 20C294.9340000000001 20 380 105.066 380 210C380 314.9342000000001 294.9340000000001 400 190 400C85.0658 400 0 314.9342000000001 0 210C0 105.066 85.0658 20 190 20zM190 60C207.576 60 224.446 63.024 240.12 68.578L253.002 108.224L229.966 139.928H153.4742L130.4388 108.224L143.7464 67.266C158.3152 62.55 173.8598 60 190 60zM70.0046 119.98H114.2584L138.6304 153.524L117.8702 218.456L71.1498 233.6362L40.0034 211.007L40 210C40 176.222 51.1646 145.052 70.0046 119.98zM340 210C340 176.222 328.836 145.052 309.996 119.98H269.182L242.42 156.812L261.93 217.8306L310.574 233.6362L339.984 212.2698C339.9940000000001 211.5146 340 210.758 340 210zM64.4906 292.1736L77.3302 252.6574L123.2688 237.731L179.9156 279.5562V317.2654L135.2702 349.7022C106.129 338.277 81.4322 317.9974 64.4906 292.1736zM304.394 252.6574L316.656 290.3974C299.672 317.1 274.474 338.0678 244.62 349.7452L199.9156 317.2654V279.6808L257.258 237.342L304.394 252.6574z" : "M300 170C300 87.158 232.842 20 150 20C67.1572 20 0 87.158 0 170C0 252.8428 67.1572 320 150 320C232.842 320 300 252.8428 300 170zM150 60C163.874 60 177.148 62.568 189.3726 67.256L196.2158 88.318L173.5898 119.46H126.7654L104.0528 88.198L110.8898 67.156C123.0418 62.532 136.2252 60 150 60zM259.978 167.7968C259.474 142.0952 250.154 118.554 234.918 100.074H212.396L189.3168 131.8392L189.281 131.8132L203.012 174.0734L236.406 184.9236L259.978 167.7968zM65.1814 99.954H87.8724L110.8118 131.5272L97.0742 173.8074L62.861 184.924L40.0126 168.3236C40.4004 142.3616 49.7824 118.58 65.1814 99.954zM160 220.735L197.6694 193.3666L230.226 203.9446L239.83 233.5032C225.888 253.189 205.638 268.0916 182.0234 275.266L160 259.265V220.735zM117.9766 275.2658L140 259.2648V220.735L102.1044 193.2022L69.0414 203.945L59.6692 232.7894C73.6248 252.8286 94.078 268.005 117.9766 275.2658z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
SoccerBall.defaultProps = {
    size: IconSize.STANDARD,
};
SoccerBall.displayName = `Blueprint5.Icon.SoccerBall`;
export default SoccerBall;
