/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LayoutThreeRows: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="layout-three-rows" ref={ref}  {...props}>
            <path
                d={isLarge ? "M0 380C0 391.0457 8.9543 400 20 400H380C391.046 400 400 391.0457 400 380V320C400 308.9544 391.046 300 380 300H20C8.95432 300 0 308.9544 0 320V380zM0 240C0 251.0456 8.9543 260 20 260H380C391.046 260 400 251.0456 400 240V160C400 148.954 391.046 140 380 140H20C8.95432 140 0 148.954 0 160V240zM0 80C0 91.046 8.9543 100 20 100H380C391.046 100 400 91.046 400 80V20C400 8.954 391.046 0 380 0H20C8.95432 0 0 8.954 0 20V80z" : "M300 80C311.046 80 320 71.046 320 60V20C320 8.954 311.046 0 300 0H20C8.9543 0 0 8.954 0 20V60C0 71.046 8.9543 80 20 80H300zM300 200C311.046 200 320 191.0456 320 180V140C320 128.9544 311.046 120 300 120H20C8.9543 120 0 128.9544 0 140V180C0 191.0456 8.9543 200 20 200H300zM300 320C311.046 320 320 311.0457 320 300V260C320 248.9544 311.046 240 300 240H20C8.9543 240 0 248.9544 0 260V300C0 311.0457 8.9543 320 20 320H300z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LayoutThreeRows.defaultProps = {
    size: IconSize.STANDARD,
};
LayoutThreeRows.displayName = `Blueprint5.Icon.LayoutThreeRows`;
export default LayoutThreeRows;
