/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const FloppyDisk: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="floppy-disk" ref={ref}  {...props}>
            <path
                d={isLarge ? "M280 380H220V280H280V380zM394.2000000000001 334.2L334.2000000000001 394.2C330.6 397.8 325.6 400 320 400H300V260H100V400H20C9 400 0 391 0 380V20C0 9 9 0 20 0H380C391 0 400 9 400 20V320C400 325.6 397.8 330.6 394.2000000000001 334.2zM340 20H60V180C60 191 69 200 80 200H320C331 200 340 191 340 180V20z" : "M314.2000000000001 274.2L274.2000000000001 314.2C270.6 317.8 265.6 320 260 320H240V200H80V320H20C9 320 0 311 0 300V20C0 9 9 0 20 0H300C311 0 320 9 320 20V260C320 265.6 317.8 270.6 314.2000000000001 274.2zM280 20H40V140C40 151 49 160 60 160H260C271 160 280 151 280 140V20zM220 300H180V220H220V300z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
FloppyDisk.defaultProps = {
    size: IconSize.STANDARD,
};
FloppyDisk.displayName = `Blueprint5.Icon.FloppyDisk`;
export default FloppyDisk;
