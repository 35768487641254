import './App.scss'

import API from '../API'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Devices from '../Pages/Devices'
import FaultsPage from '../Pages/Faults'
import LoginPage from '../Pages/Login'
import PlaceholderPage from '../Pages/Placeholder'
import ReportsPage from '../Pages/Reports'
import SettingsPage from '../Pages/Settings'

import { isAdmin } from '../Util'
import AdminRFToolsPage from '../Pages/Admin/RFTools'
import AdminRFTrafficPage from '../Pages/Admin/RFTraffic'
import AdminGSMToolsPages from '../Pages/Admin/GSMTools'
import AdminFirmaresPage from '../Pages/Admin/Firmwares'
import AdminConsolePage from '../Pages/Admin/Console'
import AdminSandboxPage from '../Pages/Admin/Sandbox'
// import AdminStatisticsPage from '../Pages/Admin/Statistics'

import { OverlayToaster, Position } from '@blueprintjs/core'
import TechnicianPage from '../Pages/Technician'
import Placeholder from './Placeholder'
export const AppToaster = OverlayToaster.createAsync({
	position: Position.TOP
})

export default class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			status: 'CONNECTING'
		}
		window.App = this
		this.user = null
		this.account = null
		this.accounts = null
	}

	componentDidMount() {
		API.addConnectionListener(this, async (connected) => {
			console.log('[App] connected =', connected, this)

			if (connected) {
				const sessionId = localStorage.getItem('sessionId')
				if (sessionId) {
					let res = await API.call('authorize', { sessionId })
					if (res.user) {
						console.log('got user', res.user)
						this.user = res.user
						this.account = res.account
						this.accounts = res.accounts
						this.setState({ status: 'ONLINE' })
						return
					} else {
						localStorage.removeItem('sessionId')
					}
				}
				this.user = null
				this.account = null
				this.accounts = null
				this.setState({ status: 'NEEDS-LOGIN' })
			} else {
				this.setState({ status: 'CONNECTING' })
			}
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	render() {
		if (this.state.status == 'CONNECTING') {
			return <Placeholder className="white-bg" loading label={'Connecting ...'} />
		} else if (this.state.status == 'NEEDS-LOGIN') {
			return <LoginPage />
		} else if (this.state.status == 'ONLINE') {
			return (
				<BrowserRouter>
					<Routes>
						<Route
							path="*"
							element={
								<PlaceholderPage
									key="404"
									title="Kļūda 404"
									message="Lapa nav atrasta!"
									icon="fa-duotone fa-triangle-exclamation"
								/>
							}
						/>

						<Route path="/" element={<Devices />} />
						<Route path="/inspector" element={<Devices inspector={true} />} />
						<Route path="/faults" element={<FaultsPage />} />
						<Route path="/reports" element={<ReportsPage />} />
						<Route path="/settings" element={<SettingsPage />} />
						<Route path="/tech" element={<TechnicianPage />} />

						{isAdmin() && (
							<>
								<Route path="/admin/rf-tools" element={<AdminRFToolsPage />} />
								<Route path="/admin/rf-traffic" element={<AdminRFTrafficPage />} />
								<Route path="/admin/gsm-tools" element={<AdminGSMToolsPages />} />
								<Route path="/admin/firmwares" element={<AdminFirmaresPage />} />
								<Route path="/admin/console" element={<AdminConsolePage />} />
								<Route path="/admin/sandbox" element={<AdminSandboxPage />} />
							</>
						)}
						{/* <Route path="/admin/statistics" element={<AdminStatisticsPage />} /> */}
					</Routes>
				</BrowserRouter>
			)
		}
	}
}
