import './index.scss'

import React from 'react'
import { DateTime } from 'luxon'
import Page from '../../../Components/Page'
import API, { RFCommand, RFPacket } from '../../../API'
import { Button, FormGroup, H5, InputGroup } from '@blueprintjs/core'

export default class extends React.Component {
	render() {
		return (
			<Page title="Admin RF Tools" className="AdminRFToolsPage" icon="fa-duotone fa-satellite-dish">
				<PacketSender />
				<RFLog />
			</Page>
		)
	}
}

class PacketSender extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			connections: API.connections
		}
		this.formRef = React.createRef()
	}

	componentDidMount() {
		API.addMessageListener(this, 'agent-list', (connections) => {
			this.setState({ connections })
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	async send() {
		const formData = new FormData(this.formRef.current)

		const p = new RFPacket()
		p.sourceId = parseInt(formData.get('sourceId'), 10)
		p.destinationId = parseInt(formData.get('destinationId'), 10)
		p.command = parseInt(formData.get('command'), 10)
		p.data = Buffer.from(formData.get('payload'), 'hex')
		const hex = p.toRaw().toString('hex')

		let agentId = formData.get('gateway')
		const sendToAgents = []
		if (agentId == 'best') {
			// TODO
		} else if (agentId == 'all') {
			sendToAgents.push(...API.connections)
		} else {
			agentId = parseInt(agentId, 10)
			for (let con of API.connections) {
				if (con.id == agentId) {
					sendToAgents.push(con)
				}
			}
		}

		for (let con of sendToAgents) {
			await API.call('agents.raw-command', {
				agentId: con.id,
				type: 50,
				payloadHex: hex
			})
		}
	}

	render() {
		const commandList = []
		for (let name in RFCommand) {
			const code = RFCommand[name]
			if (typeof code === 'number') {
				const nameStr = name + ' (#' + code + ')'
				commandList.push({ code: RFCommand[name], name: nameStr })
			}
		}

		return (
			<div className="PacketSender">
				<H5>Packet Sender</H5>
				<form ref={this.formRef}>
					<div className="Inputs">
						<FormGroup label="Gateway" labelFor="gateway">
							<select id="gateway" name="gateway">
								{/* <option value="best">Best</option> */}
								<option value="all">All</option>
								<option>-</option>
								{API.connections.map((con) => (
									<option value={con.id} key={`pscon${con.id}`}>
										{con.scanReport?.deviceId}
									</option>
								))}
							</select>
						</FormGroup>
						<FormGroup label="Source ID" labelFor="sourceId">
							<InputGroup id="sourceId" name="sourceId" defaultValue="123456" />
						</FormGroup>
						<FormGroup label="Destination ID" labelFor="destinationId">
							<InputGroup id="destinationId" name="destinationId" defaultValue="0" />
						</FormGroup>
						<FormGroup label="Command" labelFor="command">
							<select id="command" name="command">
								{commandList.map((c) => (
									<option value={c.code}>{c.name}</option>
								))}
							</select>
						</FormGroup>
					</div>
					<FormGroup label="Payload" labelFor="payload">
						<InputGroup id="payload" name="payload" placeholder="" />
					</FormGroup>
					<Button intent="primary" onClick={() => this.send()}>
						Send
					</Button>
				</form>
			</div>
		)
	}
}

class RFLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			packets: []
		}
	}

	componentDidMount() {
		API.addRFListener(this, null, (p) => {
			this.setState((prevState) => ({ packets: [p, ...prevState.packets] }))
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	clear() {
		this.setState({ packets: [] })
	}

	render() {
		return (
			<div className="RFLog">
				<H5>
					Live Traffic <Button small minimal icon="trash" onClick={() => this.clear()} />
				</H5>
				<table className="bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped">
					<thead>
						<tr>
							<th width="100">Time</th>
							<th width="70">Gateway</th>
							<th width="70">From</th>
							<th width="70">To</th>
							<th width="50">RSSI</th>
							<th>Command</th>
							<th>Payload</th>
						</tr>
					</thead>
					<tbody>
						{this.state.packets.map((p, i) => {
							// p = {
							// 	timestamp: 1725001747311,
							// 	gatewayId: 5,
							// 	sourceId: 5,
							// 	destinationId: 5,
							// 	rssi: 5,
							// 	commandName: 'test',
							// 	command: 5,
							// 	data: [1, 1]
							// }

							return (
								<tr key={`rfpacket${i}`}>
									<td>{DateTime.fromMillis(p.timestamp).toFormat('HH:mm:ss.SSS')}</td>
									<td>{p.gatewayId}</td>
									<td>{p.sourceId}</td>
									<td>{p.destinationId}</td>
									<td>{p.rssi}</td>
									<td>
										{p.commandName} (#{p.command})
									</td>
									<td>{p.data.toString('hex')}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}
}
