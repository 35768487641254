import './index.scss'

import React from 'react'
import Lang from '../../../../../Lang'
import { pick } from '../../../../../Util'
import ConditionBlock from './ConditionBlock'
import { Button, Callout, FormGroup, Slider } from '@blueprintjs/core'

// ===================================================================

export default class ProfileV2Editor extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.normalizeValues(pick(props.profile, ['daliSpeed', 'motionTime', 'conditionBlocks']))
		this.blockIds = new Map()
	}

	getData() {
		for (let cb of this.state.conditionBlocks) {
			console.log('Condition Block', cb.conditions)
		}
		console.log('- - - -')
		return this.state
	}

	getBlockId(block) {
		if (!this.blockIds.has(block)) {
			this.blockIds.set(block, Math.random())
		}
		return this.blockIds.get(block)
	}

	normalizeValues(values) {
		const p = { ...values }
		p.daliSpeed = parseInt(p.daliSpeed, 10)
		p.motionTime = parseInt(p.motionTime, 10)
		return p
	}

	setProfileValue(key, value) {
		const p = { ...this.state }
		p[key] = value
		this.setState({ ...p })
	}

	conditionBlockAction(block, action, data) {
		const index = this.state.conditionBlocks.indexOf(block)

		if (action == 'delete') {
			this.state.conditionBlocks.splice(index, 1)
		} else if (action == 'duplicate') {
			this.state.conditionBlocks.splice(index, 0, { ...block })
		} else if (action == 'move-up') {
			if (index > 0) {
				this.state.conditionBlocks.splice(index, 1)
				this.state.conditionBlocks.splice(index - 1, 0, block)
			}
		} else if (action == 'move-down') {
			if (index < this.state.conditionBlocks.length - 1) {
				this.state.conditionBlocks.splice(index, 1)
				this.state.conditionBlocks.splice(index + 1, 0, block)
			}
		} else if (action == 'add-after') {
			if (block == null) {
				this.state.conditionBlocks.unshift({ ...conditionBlockTemplate })
			} else {
				this.state.conditionBlocks.splice(index + 1, 0, { ...conditionBlockTemplate })
			}
		}

		this.forceUpdate()
	}

	render() {
		const p = this.state
		return (
			<div className="ProfileV2Editor">
				<FormGroup label={Lang.get('Brightness change speed (DALI)')}>
					<Slider max={7} value={p.daliSpeed} onChange={(val) => this.setProfileValue('daliSpeed', val)} />
				</FormGroup>

				<FormGroup label={Lang.get('Motion time')} labelInfo={'(' + Lang.get('seconds') + ')'}>
					<Slider max={600} value={p.motionTime} onChange={(val) => this.setProfileValue('motionTime', val)} labelStepSize={60} />
				</FormGroup>

				<br />
				<br />

				<Callout intent="primary">{Lang.get('The first schedule for which all conditions are true will be activated')}</Callout>

				<br />
				<br />

				<div className="conditionBlocks">
					<Button
						icon="add"
						intent="success"
						minimal
						text={Lang.get('Add Schedule')}
						onClick={() => this.conditionBlockAction(null, 'add-after')}
					/>
					{p.conditionBlocks.map((conditionBlock, index) => {
						return (
							<React.Fragment key={this.getBlockId(conditionBlock)}>
								<ConditionBlock
									data={conditionBlock}
									conditionBlockAction={(action, data) => this.conditionBlockAction(conditionBlock, action, data)}
								/>
								<Button
									icon="add"
									intent="success"
									minimal
									text={Lang.get('Add Schedule')}
									onClick={() => this.conditionBlockAction(conditionBlock, 'add-after')}
								/>
							</React.Fragment>
						)
					})}
				</div>

				<br />
				<br />
			</div>
		)
	}
}

// ===================================================================

export const conditionBlockTemplate = {
	active: true,
	comment: '',
	conditions: {},
	brightnessLevels: [
		{
			start: 0,
			duration: 21600,
			idleBrightness: 0.1,
			activeBrightness: 0.5
		},
		{
			start: 21600,
			duration: 21600,
			idleBrightness: 0.2,
			activeBrightness: 0.6
		},
		{
			start: 43200,
			duration: 21600,
			idleBrightness: 0.3,
			activeBrightness: 0.7
		},
		{
			start: 64800,
			duration: 21600,
			idleBrightness: 0.4,
			activeBrightness: 0.8
		}
	]
}

// ===================================================================

export const profileTemplate = {
	conditionBlocks: [
		// { ...conditionBlockTemplate, comment: Lang.get('September 1st'), active: false, conditions: { date: '2021-01-01' } },
		{ ...conditionBlockTemplate, comment: Lang.get('Workdays'), conditions: { weekdays: [1, 2, 3, 4, 5] } },
		{ ...conditionBlockTemplate, comment: Lang.get('Other Days') }
	]
}

// ===================================================================
