import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useEffect, useMemo, useState } from 'react'
import Lang from '../../../Lang'
import { keyDetails } from '../../Devices/Components/DeviceDetails'

export default function ParameterSelector({ onChange }) {
	const [fieldStates, setFieldStates] = useState({
		stats: {
			energyConsumption: true,
			trafficIntensity: false
		},

		d4i: {
			activePower: false,
			lampShortAddress: false,
			currentDimLevelFromSchedule: false,
			totalActiveEnergy: false,
			driverOnTime: false,
			mainsVoltage: false,
			mainsCurrent: false,
			powerFactor: false,
			driverFailureFlags: false,
			driverTemperature: false,
			ledOnTime: false,
			ledFailureFlags: false,
			ledTemperature: false,
			ledVoltage: false,
			ledCurrent: false
		},

		modbus: {
			// consumption_mb: false,
			voltage_mb: false,
			current_mb: false,
			activePower_mb: false,
			reactivePower_mb: false,
			apparentPower_mb: false,
			totalActiveEnergy_mb: false
		}
	})

	useEffect(() => {
		onChange(fieldStates)
	}, [fieldStates])

	const isAll = useMemo(() => {
		return Object.values(fieldStates).every((o) => Object.values(o).every((s) => s))
	}, [fieldStates])

	function toggleField(dic, field) {
		setFieldStates((old) => {
			old[dic][field] = !old[dic][field]
			return { ...old }
		})
	}

	function toggleDic(dic) {
		setFieldStates((old) => {
			const obj = old[dic]
			const hasAll = Object.values(obj).every((v) => v)
			for (const field in old[dic]) {
				old[dic][field] = hasAll ? false : true
			}

			return { ...old }
		})
	}

	function handleAllToggle() {
		function setAllStates(value) {
			setFieldStates((states) => {
				Object.keys(states).map((dic) => {
					Object.keys(states[dic]).map((field) => {
						states[dic][field] = value
					})
				})

				return { ...states }
			})
		}

		if (!isAll) {
			setAllStates(true)
			return
		} else {
			setAllStates(false)
		}
	}

	const menu = (
		<Menu className="scrollable">
			<MenuItem text={Lang.get('Visi')} icon={isAll ? 'tick' : 'blank'} onClick={handleAllToggle} shouldDismissPopover={false} />
			<MenuDivider />
			{Object.keys(fieldStates).map((dic, idx) => {
				const ret = []

				if (dic === 'modbus' || dic === 'd4i') {
					ret.unshift(
						<MenuDivider
							title={
								<span onClick={() => toggleDic(dic)} className="clickable-divider-title">
									{dic === 'modbus' ? Lang.get('Modbus') : Lang.get('D4I')}
								</span>
							}
							key={`divider-${dic}`}
						/>
					)
				}

				for (const field in fieldStates[dic]) {
					ret.push(
						<MenuItem
							key={`afs-${field}`}
							text={keyDetails[field]?.label || field}
							icon={fieldStates[dic][field] === true ? 'tick' : 'blank'}
							onClick={() => toggleField(dic, field)}
							shouldDismissPopover={false}
						/>
					)
				}

				return ret.map((a) => a)
			})}
		</Menu>
	)

	return (
		<Popover2 content={menu} position="bottom-right">
			<Button text={Lang.get('Parameters')} icon="form" rightIcon="caret-down" />
		</Popover2>
	)
}
