/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LayoutRightColumnThreeTiles: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="layout-right-column-three-tiles" ref={ref}  {...props}>
            <path
                d={isLarge ? "M0 380C0 391.04568 8.9543 400 20 400H160C171.0456 400 180 391.0457 180 380V20C180 8.954 171.0456 0 160 0H20C8.9543 0 0 8.954 0 20V380zM220 380C220 391.0457 228.954 400 240 400H380C391.046 400 400 391.0457 400 380V320C400 308.9544 391.046 300 380 300H240C228.954 300 220 308.9544 220 320V380zM220 240C220 251.0456 228.954 260 240 260H380C391.046 260 400 251.0456 400 240V160C400 148.954 391.046 140 380 140H240C228.954 140 220 148.954 220 160V240zM220 80C220 91.046 228.954 100 240 100H380C391.046 100 400 91.046 400 80V20C400 8.954 391.046 0 380 0H240C228.954 0 220 8.954 220 20V80z" : "M180 180C180 191.0456 188.9544 200 200 200H300C311.046 200 320 191.0456 320 180V140C320 128.9544 311.046 120 300 120H200C188.9544 120 180 128.9544 180 140V180zM180 300C180 311.0457 188.9544 320 200 320H300C311.046 320 320 311.0457 320 300V260C320 248.9544 311.046 240 300 240H200C188.9544 240 180 248.9544 180 260V300zM180 60C180 71.046 188.9544 80 200 80H300C311.046 80 320 71.046 320 60V20C320 8.954 311.046 0 300 0H200C188.9544 0 180 8.954 180 20V60zM0 300C0 311.0457 8.9543 320 20 320H120C131.0456 320 140 311.0457 140 300V20C140 8.954 131.0456 0 120 0H20C8.9543 0 0 8.954 0 20V300z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LayoutRightColumnThreeTiles.defaultProps = {
    size: IconSize.STANDARD,
};
LayoutRightColumnThreeTiles.displayName = `Blueprint5.Icon.LayoutRightColumnThreeTiles`;
export default LayoutRightColumnThreeTiles;
