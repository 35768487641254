import { useState } from 'react'
import API, { RFCommand, RFPacket } from '../../../API'
import { DeviceDetailsPingButton } from '../../Devices/Components/DeviceDetails'

export default function Actions({ device }) {
	const [gateway, setGateway] = useState(-1)

	async function deviceAction(action) {
		if (!device) return

		const p = new RFPacket()

		switch (action) {
			case 'on':
				p.command = RFCommand.RELAY_TEST
				p.data = Buffer.from('01', 'hex')
				break
			case 'off':
				p.command = RFCommand.RELAY_TEST
				p.data = Buffer.from('00', 'hex')
				break
			case 'blink':
				p.command = RFCommand.IDENTIFY
				break
			default:
				console.error('Unknown action: ' + action)
				return
		}

		API.sendPacket(p, device, gateway)
	}

	// console.log(API.connections)

	return (
		<div className="device-actions">
			<div>
				Gateway:{' '}
				<div className="bp5-html-select">
					<select value={gateway} onChange={(e) => setGateway(parseInt(e.target.value))}>
						<option value={-1}> </option>
						{API.connections.map((con) => (
							<option value={con.id} key={`tgs${con.id}`}>
								{con.scanReport?.deviceId}
							</option>
						))}
					</select>
					<span className="bp5-icon bp5-icon-double-caret-vertical"></span>
				</div>
			</div>

			<div>
				<button className="bp5-button" onClick={() => deviceAction('on')}>
					Relay ON
				</button>
				<button className="bp5-button" onClick={() => deviceAction('off')}>
					Relay OFF
				</button>
			</div>

			<div>
				<button className="bp5-button" onClick={() => deviceAction('blink')}>
					<i className="fa-solid fa-lightbulb"></i> Blink DALI
				</button>
				<DeviceDetailsPingButton device={device} preferGateway={gateway} />
			</div>
		</div>
	)
}
