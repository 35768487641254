import './index.scss'
import React from 'react'
import mapboxgl from 'mapbox-gl'
import App from './Components/App'
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactDOM from 'react-dom'
import { FocusStyleManager } from '@blueprintjs/core'
import API from './API'

FocusStyleManager.onlyShowFocusOnTabs()

mapboxgl.accessToken = 'pk.eyJ1IjoiZW1vcmFpdmlzIiwiYSI6ImNsOWNyOWF2dTF3bGMzcmxtOHUwd2d0YnYifQ.SyOOwd_WDYDpAdhPR5YK_g'

ReactDOM.render(<App />, document.getElementById('App'))

window.API = API
