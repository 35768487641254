/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Trophy: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="trophy" ref={ref}  {...props}>
            <path
                d={isLarge ? "M100 380C100 391.04568 108.9544 400 120 400H280C291.046 400 300 391.0457 300 380V340H380C391.046 340 400 331.0456000000001 400 320V280C400 224.7716 355.228 180 300 180H291.68C278.826 150.582 252.226 128.542 220 122V40H280C291.046 40 300 31.046 300 20C300 8.954 291.046 0 280 0H120C108.9544 0 100 8.954 100 20C100 31.046 108.9544 40 120 40H180V122C147.7734 128.542 121.1746 150.582 108.3208 180H100C44.7716 180 0 224.7716 0 280V320C0 331.0456000000001 8.9543 340 20 340H100V380zM100 300H40V280C40 246.863 66.863 220 100 220V300zM300 220V300H360V280C360 246.863 333.1380000000001 220 300 220z" : "M80 301.66666C80 311.79188 88.2082 320 98.3334 320H221.666C231.7920000000001 320 240 311.79188 240 301.66666V280H300C311.046 280 320 271.0456 320 260V240C320 187.9948 280.302 145.2616 229.556 140.4508C218.98 121.89 201.188 107.974 180 102.52V40H220C231.046 40 240 31.046 240 20C240 8.954 231.046 0 220 0H100C88.9544 0 80 8.954 80 20C80 31.046 88.9544 40 100 40H140V102.52C118.8114 107.974 101.0202 121.89 90.4438 140.4508C39.698 145.2616 0 187.9948 0 240V260C0 271.0456 8.9543 280 20 280H80V301.66666zM80 240H40C40 213.8756 56.6962 191.6508 80 183.4142V240zM240 183.4142V240H280C280 213.8756 263.3040000000001 191.6508 240 183.4142z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Trophy.defaultProps = {
    size: IconSize.STANDARD,
};
Trophy.displayName = `Blueprint5.Icon.Trophy`;
export default Trophy;
