/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Crop: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="crop" ref={ref}  {...props}>
            <path
                d={isLarge ? "M280 20C280 8.954 288.954 0 300 0C311.046 0 320 8.954 320 20L320 80L380 80C391.046 80 400 88.954 400 100C400 111.046 391.046 120 380 120L120 120L120 380.00002C120 391.04574 111.0458 400.0000252612 100 400.0000262268C88.9544 400.0000271924 80 391.04576 80 380.00002L80 320L20 320C8.95434 320 0.0000271924 311.0458 0.0000262268 300C0.0000252612 288.9544 8.95434 280 20 280L80 280L80 100C80 88.954 88.9544 80 100 80L280 80L280 20zM300 320L160 320L160 280L280 280L280 160L320 160L320 300.0002C320 311.0458 311.046 320 300 320zM0 400H400V0H0V400z" : "M220 20C220 8.954 228.954 0 240 0C251.046 0 260 8.954 260 20V60H300C311.046 60 320 68.954 320 80C320 91.046 311.046 100 300 100H100.0002V300C100.0002 311.0457 91.0458 319.999999034354 80.0002 320C68.9544 320.000000965646 60.0002 311.04568 60.0002 300L60.0002 260L20.0002 260C8.95444 260 0.0001154066 251.0456 0.000152588 240C0.0001516224 228.9542 8.95444 220 20.0002 220L60.0002 220L60.0002 80C60.0002 68.954 68.9544 60 80.0002 60H220V20zM240 260.0002L120.0002 260.0002V220.0002L220 220.0002L220 120H260L260 240.0002C260 251.0458 251.046 260.0002 240 260.0002z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Crop.defaultProps = {
    size: IconSize.STANDARD,
};
Crop.displayName = `Blueprint5.Icon.Crop`;
export default Crop;
