import { Button, ButtonGroup, Dialog, DialogBody, DialogFooter, MenuDivider, Spinner } from '@blueprintjs/core'
import Lang from '../../../Lang'
import { useState } from 'react'

export default function ExportDialog({ asyncDownload, title, open, setOpen, children }) {
	const [loading, setLoading] = useState(false)
	const [exportType, setExportType] = useState('raw')

	return (
		<Dialog isOpen={open} onClose={() => setOpen(false)} title={title} icon="document">
			<DialogBody>
				{children}

				<MenuDivider title={Lang.get('Export type')} />
				<ButtonGroup className="data-avg-btn-group">
					<Button intent={exportType == 'raw' ? 'primary' : 'none'} onClick={() => setExportType('raw')}>
						{Lang.get('Raw')}
					</Button>
					<Button intent={exportType == 'chart' ? 'primary' : 'none'} onClick={() => setExportType('chart')}>
						{Lang.get('Chart')}
					</Button>
				</ButtonGroup>
			</DialogBody>
			<DialogFooter
				actions={
					<>
						<Button text={Lang.get('Cancel')} onClick={() => setOpen(false)} />
						<Button
							text={Lang.get('Download')}
							intent="primary"
							icon={loading ? <Spinner className="spinner-icon white" /> : 'download'}
							onClick={async () => {
								setLoading(true)
								await asyncDownload(exportType)
								setOpen(false)
								setLoading(false)
							}}
						/>
					</>
				}></DialogFooter>
		</Dialog>
	)
}
