import { DateTime } from 'luxon'
import Lang from './Lang'

export function AdminOnly(props) {
	if (isAdmin()) {
		return props.children
	}
	return null
}

export function isAdmin() {
	return window.App.user?.isAdmin === 1
}

export function pick(obj, keys) {
	return keys.reduce((acc, key) => {
		if (obj.hasOwnProperty(key)) {
			acc[key] = obj[key]
		}
		return acc
	}, {})
}

export function dali2percents(dali) {
	if (dali <= 0) {
		return 0
	} else if (dali > 254) {
		dali = 254
	}
	return Math.round(Math.pow(10, (dali - 1) / (253 / 3) - 1))
}

export function percents2dali(percents) {
	if (percents < 0) {
		return 0
	} else if (percents > 100) {
		percents = 100
	}
	return Math.round((Math.log10(percents) + 1) * (253 / 3) + 1)
}

export function addEmptinessPoints(data, key) {
	const nextBuilt = []
	for (let i = 0; i < data.length; i++) {
		const cur = data[i]
		nextBuilt.push(cur)
		if (i < data.length - 1) {
			const next = data[i + 1]
			const diff = next.time.diff(cur.time)

			if (diff.values.milliseconds > 3600000) {
				nextBuilt.push({
					time: cur.time,
					[key]: 0
				})
			}
		}
	}
	data = nextBuilt

	// check for prev
	const prevBuilt = []
	for (let i = 0; i < data.length; i++) {
		const cur = data[i]
		if (cur.time.toFormat('HH:mm') != '23:59') {
			if (i > 0) {
				const prev = data[i - 1]
				const diff = cur.time.diff(prev.time)

				if (diff.values.milliseconds > 3600000) {
					prevBuilt.push({
						time: cur.time,
						[key]: 0
					})
				}
			}
		}
		prevBuilt.push(cur)
	}
	return prevBuilt
}

import { useEffect, useRef } from 'react'

const DEFAULT_CONFIG = {
	timeout: 0,
	ignoreInitialCall: false
}
export function useDebouncedEffect(callback, config, deps = []) {
	let currentConfig
	if (typeof config === 'object') {
		currentConfig = {
			...DEFAULT_CONFIG,
			...config
		}
	} else {
		currentConfig = {
			...DEFAULT_CONFIG,
			timeout: config
		}
	}
	const { timeout, ignoreInitialCall } = currentConfig
	const data = useRef({ firstTime: true })
	useEffect(() => {
		const { firstTime, clearFunc } = data.current

		if (firstTime && ignoreInitialCall) {
			data.current.firstTime = false
			return
		}

		const handler = setTimeout(() => {
			if (clearFunc && typeof clearFunc === 'function') {
				clearFunc()
			}
			data.current.clearFunc = callback()
		}, timeout)

		return () => {
			clearTimeout(handler)
		}
	}, [timeout, ...deps])
}

export function roundToFirstTwoNonZeroDecimals(num) {
	const strNum = num.toString()
	const decimalIndex = strNum.indexOf('.')
	if (decimalIndex === -1) {
		return num // int
	}

	let nonZeroIndex = decimalIndex + 1
	let foundNonZero = false
	let nonZeroCount = 0
	while (nonZeroIndex < strNum.length && nonZeroCount < 2) {
		if (strNum[nonZeroIndex] !== '0') {
			foundNonZero = true
			nonZeroCount++
		}
		nonZeroIndex++
	}

	if (!foundNonZero) {
		return Number(strNum.slice(0, decimalIndex)) // all
	}

	const decimalPlaces = nonZeroIndex - decimalIndex - 1
	return Number(num.toFixed(decimalPlaces))
}

export const download = (data, title = 'download') => {
	const blob = new Blob([data], { type: 'text/csv' })

	const url = URL.createObjectURL(blob)
	const a = document.createElement('a')

	a.href = url
	a.download = `${title}.csv`

	a.click()
}

export function findKeyByProperty(obj, property) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (key === property || findKeyByProperty(obj[key], property)) {
				return key
			}
		}
	}
	return null // Property not found
}

export function isDeviceGateway(device) {
	for (const gtw of device.gateways) {
		if (gtw.serialNr === device.serialNr && DateTime.fromSeconds(gtw.lastDataTime).diffNow('days').toObject().days > -7) {
			return true
		}
	}
	return false
}
