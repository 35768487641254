import './DevicePicker.scss'

import React from 'react'
import Lang from '../Lang'
import Placeholder from './Placeholder'
import { Classes } from '@blueprintjs/popover2'
import { Button, Checkbox } from '@blueprintjs/core'

export default class DevicePicker extends React.Component {
	constructor(props) {
		super(props)
		this.state = { profiles: null, selectedDeviceIds: this.props.selectedDeviceIds }
	}

	componentDidMount() {
		this.reload()
	}

	async reload() {
		const [apiProfiles, apiDevices] = await Promise.all([API.call('LightingProfile.List'), API.getDevices()])
		apiDevices.devices.sort((a, b) => a.id - b.id)
		const profiles = {
			0: {
				id: 0,
				title: Lang.get('Other Devices'),
				devices: []
			}
		}
		for (const p of apiProfiles) {
			p.devices = []
			profiles[p.id] = p
		}
		for (const d of apiDevices.devices) {
			if (d.lightingProfileId in profiles) {
				profiles[d.lightingProfileId].devices.push(d)
			} else {
				profiles[0].devices.push(d)
			}
		}
		if (profiles[0].devices.length === 0) {
			delete profiles[0]
		}
		this.setState({ profiles: Object.values(profiles) })
	}

	confirm() {
		this.props.onChange(this.state.selectedDeviceIds)
	}

	toggleDeviceId(deviceId) {
		const selectedDeviceIds = [...this.state.selectedDeviceIds]
		const index = selectedDeviceIds.indexOf(deviceId)
		if (index === -1) {
			selectedDeviceIds.push(deviceId)
		} else {
			selectedDeviceIds.splice(index, 1)
		}
		this.setState({ selectedDeviceIds })
	}

	render() {
		return (
			<div className="DevicePicker">
				{!this.state.profiles && <Placeholder loading />}
				{this.state.profiles && (
					<>
						<div className="content">
							{this.state.profiles.map((p) => {
								return (
									<div className="LightProfile" key={p.id}>
										<div className="profileTitle">{p.title}</div>
										<div className="deviceList">
											{p.devices.map((d) => (
												<div className="Device" key={d.id}>
													<Checkbox
														checked={this.state.selectedDeviceIds.indexOf(d.id) !== -1}
														onChange={() => this.toggleDeviceId(d.id)}>
														<i className="fa-fw fa-duotone fa-router" />
														<div className="deviceId">{d.id}</div>
													</Checkbox>
												</div>
											))}
										</div>
									</div>
								)
							})}
						</div>
						<div className="buttons">
							<Button
								text={Lang.get('Confirm')}
								icon="tick"
								intent="success"
								onClick={() => this.confirm()}
								className={Classes.POPOVER2_DISMISS}
							/>
						</div>
					</>
				)}
			</div>
		)
	}
}
