/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LayoutRightColumnTwoTiles: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="layout-right-column-two-tiles" ref={ref}  {...props}>
            <path
                d={isLarge ? "M220 380C220 391.0457 228.954 400 240 400H380C391.046 400 400 391.0457 400 380V240C400 228.9544 391.046 220 380 220H240C228.954 220 220 228.9544 220 240V380zM220 160C220 171.046 228.954 180 240 180H380C391.046 180 400 171.046 400 160V20C400 8.954 391.046 0 380 0H240C228.954 0 220 8.954 220 20V160zM0 380C0 391.04568 8.9543 400 20 400H160C171.0456 400 180 391.0457 180 380V20C180 8.954 171.0456 0 160 0H20C8.9543 0 0 8.954 0 20V380z" : "M180 300C180 311.0457 188.9544 320 200 320H300C311.046 320 320 311.0457 320 300V200C320 188.9544 311.046 180 300 180H200C188.9544 180 180 188.9544 180 200V300zM180 120C180 131.0456 188.9544 140 200 140H300C311.046 140 320 131.0456 320 120V20C320 8.954 311.046 0 300 0H200C188.9544 0 180 8.954 180 20V120zM0 300C0 311.0457 8.9543 320 20 320H120C131.0456 320 140 311.0457 140 300V20C140 8.954 131.0456 0 120 0H20C8.9543 0 0 8.954 0 20V300z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LayoutRightColumnTwoTiles.defaultProps = {
    size: IconSize.STANDARD,
};
LayoutRightColumnTwoTiles.displayName = `Blueprint5.Icon.LayoutRightColumnTwoTiles`;
export default LayoutRightColumnTwoTiles;
