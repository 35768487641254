import './index.scss'

import React from 'react'
import Page from '../../Components/Page'
import Placeholder from '../../Components/Placeholder'

export default class extends React.Component {
	render() {
		return (
			<Page title={this.props.title} icon={this.props.icon} className="PlaceholderPage">
				<Placeholder icon={this.props.icon} label={this.props.message} />
			</Page>
		)
	}
}
