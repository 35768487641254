import { Button, Dialog, DialogBody, DialogFooter, FormGroup, InputGroup } from '@blueprintjs/core'

export default function EnterCodeDialog({ open, setOpen, onEnter }) {
	async function handleEnter() {
		const res = await onEnter(document.getElementById('code-input').value)
		if (res === true) setOpen(false)
	}

	return (
		<Dialog isOpen={open} onClose={() => setOpen(false)}>
			<DialogBody>
				<FormGroup helperText="A device ID that is in the current account" label="Device ID" labelFor="code-input">
					<InputGroup id="code-input" placeholder="ID" />
				</FormGroup>
			</DialogBody>
			<DialogFooter
				actions={
					<Button intent="primary" onClick={handleEnter}>
						Enter
					</Button>
				}
			/>
		</Dialog>
	)
}
