import './index.scss'

import Map from '../../Components/Map'
import { useCallback, useEffect, useRef, useState } from 'react'
import API, { RFCommand, RFPacket } from '../../API'
import mapboxgl from 'mapbox-gl'
import { DateTime } from 'luxon'
import Lang from '../../Lang'
import Placeholder from '../../Components/Placeholder'
import QRDialog from './Components/QRDialog'
import { AppToaster } from '../../Components/App'
import { Button, FormGroup, InputGroup, Menu, MenuItem, Spinner } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import EnterCodeDialog from './Components/EnterCodeDialog'
import { DeviceDetailsPingButton } from '../Devices/Components/DeviceDetails'
import Actions from './Components/Actions'
import { isDeviceGateway } from '../../Util'
import AccountSelector from '../../Components/AccountSelector'

function createMarkerElementForDevice(dev) {
	const markerElement = document.createElement('div')
	markerElement.className = 'Marker DeviceMarker'
	markerElement.style.backgroundColor = 'gray'

	const iconElement = document.createElement('i')
	const deviceIcon = dev.customIcon ?? 'lightbulb'
	iconElement.className = 'fa-fw fa-duotone fa-' + deviceIcon
	markerElement.appendChild(iconElement)

	const labelElement = document.createElement('div')
	labelElement.className = 'label'
	labelElement.innerText = dev.id
	markerElement.appendChild(labelElement)

	if (isDeviceGateway(dev)) {
		const gatewayIcon = document.createElement('i')
		gatewayIcon.className = 'gatewayIcon fa-fw fa-duotone fa-wifi'
		markerElement.appendChild(gatewayIcon)
	}

	return markerElement
}

export default function TechnicianPage() {
	const [devices, setDevices] = useState(null)

	const [enterOpen, setEnterOpen] = useState(false)
	const [qrOpen, setQrOpen] = useState(false)

	const [editingDevice, setEditingDevice] = useState({
		title: '',
		latitude: 0,
		longitude: 0
	})
	const [saving, setSaving] = useState(0)
	const [unsavedChanges, setUnsavedChanges] = useState(false)
	async function handleSave() {
		if (!activeDevice) return

		setSaving(1)
		await API.call('Admin.Device.Update', {
			id: activeDevice.id,
			...editingDevice
		})

		setDevices((dev) => {
			return dev.map((d) => {
				if (d.id == activeDevice.id) {
					d = { ...d, ...editingDevice }
					d.marker.setLngLat([d.longitude, d.latitude])
				}

				return d
			})
		})

		setSaving(2)
		setTimeout(() => setSaving(0), 1000)

		setUnsavedChanges(false)
	}

	const [selectingFromPin, setSelectingFromPin] = useState(false)
	const [activeDevice, setActiveDevice] = useState(null)
	const mapRef = useRef(null)

	function getMap() {
		return mapRef.current.map
	}

	function flyToDevice(device, fromMap = false) {
		const coords = [device.longitude, device.latitude]
		if (!coords.every((c) => c)) return false

		getMap().flyTo({
			center: coords,
			...(!fromMap && { zoom: 22 }),
			essential: true
		})

		return true
	}

	async function reload() {
		const devices = await API.getDevices()
		if (devices.error) return

		setDevices(devices.devices)
		for (const d of devices.devices) {
			if (!d.latitude || !d.longitude) continue

			const deviceCoord = [d.longitude, d.latitude]

			const markerElement = createMarkerElementForDevice(d)
			const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat(deviceCoord).addTo(getMap())
			// markerElement.addEventListener('click', async (e) => {
			// 	await selectDevice(d.id, true)
			// })
			d.marker = marker
		}
	}

	const centerPinRef = useRef(null)

	const onMapMove = useCallback(() => {
		const center = getMap().getCenter()
		centerPinRef.current.setLngLat(center)
	}, [])

	function startSelectingFromPin() {
		setSelectingFromPin(true)

		const centerPinEl = document.createElement('div')
		centerPinEl.className = 'center-pin'
		centerPinRef.current = new mapboxgl.Marker({ element: centerPinEl }).setLngLat(getMap().getCenter()).addTo(getMap())
		getMap().on('move', onMapMove)
	}

	function stopSelectingFromPin() {
		setSelectingFromPin(false)

		const { lat, lng } = centerPinRef.current.getLngLat()

		setUnsavedChanges(true)
		setEditingDevice((o) => {
			return { ...o, latitude: lat, longitude: lng }
		})

		centerPinRef.current.remove()
		getMap().off('move', onMapMove)
	}

	useEffect(() => {
		reload()
	}, [])

	async function selectDevice(deviceId, fromMap = false) {
		if (!devices) return

		// check if id is ok
		const device = devices.find((d) => d.id == deviceId)
		if (!device) {
			;(await AppToaster).show({ message: 'Device ID not valid', intent: 'warning' })
			return false
		}

		console.log(device)

		setActiveDevice(device)
		setEditingDevice({
			...{
				title: device.title,
				latitude: device.latitude ?? '',
				longitude: device.longitude ?? ''
			}
		})
		setUnsavedChanges(false)

		const isSetUp = flyToDevice(device, fromMap)
		if (!isSetUp) {
			// not set up
			// ;(await AppToaster).show({ message: 'Device not set up', intent: 'primary' })
		}

		return true
	}

	async function onQRScan(data) {
		// check if valid idealights scan
		const [prefix, code] = data.split('_')
		if (prefix !== 'idealights') {
			;(await AppToaster).show({ message: 'Invalid QR', intent: 'warning' })
			return false
		}

		return await selectDevice(code)
	}

	async function onCodeEnter(code) {
		return await selectDevice(code)
	}

	const middle = (
		<div className="details" data-inactive={activeDevice == null || selectingFromPin}>
			<div className="close-btn" onClick={() => setActiveDevice(null)}>
				<i className="fa-solid fa-xmark"></i>
			</div>

			{activeDevice != null && (
				<div className="details__body">
					<div>
						Device ID: <strong>{activeDevice.id}</strong>
					</div>

					<FormGroup label={<strong>Title</strong>} labelFor="device-title-input" style={{ margin: 0 }}>
						<InputGroup
							id="device-title-input"
							placeholder="Title"
							defaultValue={editingDevice.title}
							onChange={(e) => {
								setUnsavedChanges(true)
								setEditingDevice((o) => {
									return { ...o, title: e.target.value }
								})
							}}
						/>
					</FormGroup>

					<div className="location-group">
						<strong>Location</strong>
						<div className="coordinates">
							<FormGroup label="Latitude" labelFor="device-lat-input" style={{ margin: 0 }}>
								<InputGroup
									id="device-lat-input"
									placeholder="Latitude"
									value={editingDevice.latitude}
									onChange={(e) => {
										setUnsavedChanges(true)
										setEditingDevice((o) => {
											return { ...o, latitude: e.target.value }
										})
									}}
								/>
							</FormGroup>

							<FormGroup label="Longitude" labelFor="device-lng-input" style={{ margin: 0 }}>
								<InputGroup
									id="device-lng-input"
									placeholder="Longitude"
									value={editingDevice.longitude}
									onChange={(e) => {
										setUnsavedChanges(true)
										setEditingDevice((o) => {
											return { ...o, longitude: e.target.value }
										})
									}}
								/>
							</FormGroup>
						</div>
						<Button icon={'pin'} onClick={startSelectingFromPin}>
							Select on map
						</Button>
					</div>
				</div>
			)}

			{(unsavedChanges || saving == 2) && (
				<Button
					intent="primary"
					className="save-btn"
					onClick={handleSave}
					icon={saving == 1 ? <Spinner className="spinner-icon white" /> : saving == 2 ? 'tick' : 'blank'}>
					Save
				</Button>
			)}
		</div>
	)

	const bottom = (
		<div className="enter">
			{(() => {
				if (selectingFromPin) {
					return (
						<Button intent="primary" icon={'pin'} onClick={stopSelectingFromPin}>
							Select
						</Button>
					)
				}

				if (!activeDevice) {
					return (
						<>
							<button className="bp5-button" onClick={() => setEnterOpen(true)}>
								<i className="fa-solid fa-pencil"></i> Enter code
							</button>
							<button className="bp5-button" onClick={() => setQrOpen(true)}>
								<i className="fa-solid fa-qrcode"></i> Scan QR
							</button>
						</>
					)
				}

				return <Actions device={activeDevice} />
			})()}
		</div>
	)

	return (
		<>
			{devices === null ? (
				<Placeholder className="white-bg" loading label={Lang.get('Loading devices') + ' ...'} />
			) : (
				<>
					<main className="technician-page">
						<div className="overlay">
							{!activeDevice && (
								<div className="account">
									<AccountSelector />
								</div>
							)}

							{middle}

							{bottom}
						</div>

						<Map ref={mapRef} />
					</main>

					<QRDialog open={qrOpen} setOpen={setQrOpen} onScan={onQRScan} />
					<EnterCodeDialog open={enterOpen} setOpen={setEnterOpen} onEnter={onCodeEnter} />
				</>
			)}
		</>
	)
}
