import './Map.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

import React from 'react'
import Lang from '../Lang'
import mapboxgl from 'mapbox-gl'
import { Popover2 } from '@blueprintjs/popover2'
import { Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'

import virsiTvaikaLayer from '../Assets/overlays/virsi-tvaika.png'
import virsiSateklesLayer from '../Assets/overlays/virsi-satekles.png'
import virsiOlaineLayer from '../Assets/overlays/virsi-olaine.png'
import virsiViskaluLayer from '../Assets/overlays/virsi-viskalu.png'
import virsiSiguldaLayer from '../Assets/overlays/virsi-sigulda.png'
import virsiSalaspilsLayer from '../Assets/overlays/virsi-salaspils.png'

export default class Map extends React.Component {
	constructor(props) {
		super(props)
		this.mapRef = React.createRef()
		this.state = {
			style: 'map',
			iconColors: 'lighting-profile'
		}
	}

	componentWillUnmount() {
		this.map.remove()
	}

	componentDidMount() {
		this.map = new mapboxgl.Map({
			container: this.mapRef.current,
			style: 'mapbox://styles/mapbox/navigation-day-v1',
			center: [24.1056, 56.9677],
			zoom: 6
			// projection: 'globe'
		})

		this.map.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true
				},
				trackUserLocation: true,
				showUserHeading: true
			})
		)

		// Place Virsi overlay
		const overlays = [
			{
				id: 'virsi-tvaika',
				image: virsiTvaikaLayer,
				accountId: 111,
				coordinates: [
					[24.1152479228463, 56.99838186651575],
					[24.11717991915333, 56.99838186651575],
					[24.11717991915333, 56.99738496280126],
					[24.11524722222222, 56.99738611111111]
				]
			},
			{
				id: 'virsi-satekles',
				image: virsiSateklesLayer,
				accountId: 108,
				coordinates: [
					[24.12795931083174, 56.94712618505658],
					[24.12795285641126, 56.94767987844558],
					[24.12967176876184, 56.94767979296833],
					[24.12966284796274, 56.94716272048972]
				]
			},
			{
				id: 'virsi-olaine',
				image: virsiOlaineLayer,
				accountId: 113,
				coordinates: [
					[23.968173015512598, 56.795743636036995],
					[23.971072778660297, 56.795752058867365],
					[23.971083612790235, 56.79462893222509],
					[23.968183849964586, 56.79462050939567]
				]
			},
			{
				id: 'virsi-viskalu',
				image: virsiViskaluLayer,
				accountId: 114,
				coordinates: [
					[24.181836947533316, 56.98413084151486],
					[24.18360051268902, 56.98414496892944],
					[24.183637166768484, 56.982785241232264],
					[24.181873602937525, 56.9827711138284]
				]
			},
			{
				id: 'virsi-sigulda',
				image: virsiSiguldaLayer,
				accountId: 115,
				coordinates: [
					[24.876758150837663, 57.150869426827406],
					[24.879487990895974, 57.15083631966974],
					[24.879444818684757, 57.149789814310935],
					[24.876714977366543, 57.14982292148342]
				]
			},
			{
				id: 'virsi-salaspils',
				image: virsiSalaspilsLayer,
				accountId: 116,
				coordinates: [
					[24.35955736723016, 56.848114626377814],
					[24.3620531647819, 56.84809966012389],
					[24.362027416923965, 56.84681762908176],
					[24.35953161847991, 56.84683259534099]
				]
			}
		]
		this.map.on('style.load', () => {
			for (let overlay of overlays) {
				if (overlay.accountId == window.App.account?.id) {
					this.map.addSource(overlay.id + '-layer', {
						type: 'image',
						url: overlay.image,
						coordinates: overlay.coordinates
					})
					this.map.addLayer({
						id: 'overlays-layer',
						type: 'raster',
						source: overlay.id + '-layer'
					})
					this.map.setPaintProperty('overlays-layer', 'raster-opacity', 0.8)
				}
			}
		})

		this.map.on('contextmenu', (e) => {
			console.log(e.lngLat.lat, e.lngLat.lng)
		})
	}

	setViewState(newState) {
		this.setState(newState, () => {
			if (newState.style == 'satellite') {
				this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v12')
			} else if (newState.style == 'map') {
				this.map.setStyle('mapbox://styles/mapbox/navigation-day-v1')
			}
			if (this.props.onViewStateChange) {
				this.props.onViewStateChange(newState)
			}
		})
	}

	render() {
		const viewMenu = (
			<Menu>
				<MenuDivider title={Lang.get('View Type')} />
				<MenuItem
					icon={this.state.style == 'map' ? 'tick' : 'blank'}
					text={Lang.get('Map')}
					onClick={() => this.setViewState({ style: 'map' })}
				/>
				<MenuItem
					icon={this.state.style == 'satellite' ? 'tick' : 'blank'}
					text={Lang.get('Satellite')}
					onClick={() => this.setViewState({ style: 'satellite' })}
				/>
				<MenuDivider title={Lang.get('Icon Color')} />
				<MenuItem
					icon={this.state.iconColors == 'lighting-profile' ? 'tick' : 'blank'}
					text={Lang.get('Lighting Profile')}
					onClick={() => this.setViewState({ iconColors: 'lighting-profile' })}
				/>
				<MenuItem
					icon={this.state.iconColors == 'device-status' ? 'tick' : 'blank'}
					text={Lang.get('Device Status')}
					onClick={() => this.setViewState({ iconColors: 'device-status' })}
				/>
				<MenuItem
					icon={this.state.iconColors == 'report-health' ? 'tick' : 'blank'}
					text={Lang.get('Report Health')}
					onClick={() => this.setViewState({ iconColors: 'report-health' })}
				/>
				<MenuItem
					icon={this.state.iconColors == 'operative' ? 'tick' : 'blank'}
					text={Lang.get('Operative')}
					onClick={() => this.setViewState({ iconColors: 'operative' })}
				/>
			</Menu>
		)
		return (
			<div className="Map">
				<div className="tools">
					<Popover2 content={viewMenu} placement="bottom">
						<Button icon="eye-open" />
					</Popover2>
				</div>
				<div className="container" ref={this.mapRef} />
			</div>
		)
	}
}
