import './index.scss'

import React from 'react'
import Page from '../../../Components/Page'

export default class extends React.Component {
	render() {
		return (
			<Page title="Sandbox" className="SandboxPage" icon="fa-duotone fa-shovel">
				Placeholder for Sandbox
			</Page>
		)
	}
}
