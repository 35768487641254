import React from 'react'
import Lang from '../../../../Lang'
import { Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import DevicePicker from '../../../../Components/DevicePicker'

export default class DeviceChoice extends React.Component {
	constructor(props) {
		super(props)
		this.state = { deviceIds: props.deviceIds }
	}

	removeDevice(deviceId) {
		this.setState((prevState) => ({
			deviceIds: prevState.deviceIds.filter((id) => id !== deviceId)
		}))
	}

	setSelectedDeviceIds(deviceIds) {
		this.setState({ deviceIds })
	}

	render() {
		const { deviceIds } = this.state
		return (
			<div className="DeviceChoice">
				<div className="heading">{Lang.get('Devices')}</div>
				<div className="deviceList">
					{deviceIds.map((id) => (
						<div className="device" key={`device-${id}`}>
							<i className="fa-fw fa-duotone fa-router" />
							<div className="title">{id}</div>
							<Button icon="cross" minimal small intent="danger" onClick={() => this.removeDevice(id)} />
						</div>
					))}
					<div className="buttons">
						<Popover2
							content={
								<DevicePicker
									selectedDeviceIds={deviceIds}
									onChange={(newDeviceIds) => this.setSelectedDeviceIds(newDeviceIds)}
								/>
							}>
							<Button text={Lang.get('Add Devices')} icon="plus" intent="primary" minimal />
						</Popover2>
					</div>
				</div>
			</div>
		)
	}
}
