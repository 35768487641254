import './index.scss'

import React from 'react'
import API from '../../API'
import Lang from '../../Lang'
import Page from '../../Components/Page'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'

import logoUrl from '../../Assets/logo.png'
import mp4Video from 'url:../../Assets/login-video.mp4'
import webmVideo from 'url:../../Assets/login-video.webm'
import LanguageSelector from '../../Components/LanguageSelector'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.loginRef = React.createRef()
		this.passwordRef = React.createRef()
	}

	render() {
		return (
			<Page title="Login" className="LoginPage bp5-dark" fullscreen>
				<video playsInline autoPlay muted loop controls={false}>
					<source src={mp4Video} type="video/mp4" />
					<source src={webmVideo} type="video/webm" />
				</video>
				<form onSubmit={(e) => this.submit(e)}>
					<div className="LogoContainer">
						<img src={logoUrl} className="Logo" />
					</div>
					<div className="InputContainer">
						<FormGroup label={Lang.get('E-mail')} labelFor="loginInput">
							<InputGroup id="loginInput" inputRef={this.loginRef} autoFocus />
						</FormGroup>

						<FormGroup label={Lang.get('Password')} labelFor="passwordInput">
							<InputGroup id="passwordInput" inputRef={this.passwordRef} type="password" />
						</FormGroup>

						<FormGroup>
							<Button fill type="submit" intent="primary">
								{Lang.get('Continue')}
							</Button>
						</FormGroup>

						<LanguageSelector />
					</div>
				</form>
			</Page>
		)
	}

	async submit(e) {
		e.preventDefault()

		let res = await API.call('authorize', {
			email: this.loginRef.current.value,
			password: this.passwordRef.current.value
		})

		console.log({ res })

		if (res.user) {
			localStorage.setItem('sessionId', res.sessionId)
			location.reload()
		} else {
			alert(Lang.get('Invalid login or password'))
		}
	}
}
