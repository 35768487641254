import { Button, ButtonGroup, Menu, MenuDivider, MenuItem, Switch } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useState } from 'react'
import Lang from '../../../Lang'

export default function ChartViewSettings({ onChange }) {
	const [viewSettings, setViewSettings] = useState({
		step: 'hour'
	})

	function setSetting(key, val) {
		setViewSettings((old) => {
			old[key] = val
			onChange(old)
			return { ...old }
		})
	}

	return (
		<Popover2
			position="bottom-right"
			content={
				<Menu>
					<MenuDivider title={Lang.get('Step')} />
					<ButtonGroup className="data-avg-btn-group">
						<Button intent={viewSettings.step == 'hour' ? 'primary' : 'none'} onClick={() => setSetting('step', 'hour')}>
							{Lang.get('Hour')}
						</Button>
						<Button intent={viewSettings.step == 'day' ? 'primary' : 'none'} onClick={() => setSetting('step', 'day')}>
							{Lang.get('Day')}
						</Button>
						<Button intent={viewSettings.step == 'month' ? 'primary' : 'none'} onClick={() => setSetting('step', 'month')}>
							{Lang.get('Month')}
						</Button>
					</ButtonGroup>
				</Menu>
			}>
			<Button icon="settings" />
		</Popover2>
	)
}
