import { sprintf } from 'sprintf-js'

import lvTranslations from './Translations/lv.json'

class Lang_ {
	public availableLanguages = {
		en: {
			name: 'English',
			tanslations: {}
		},
		lv: {
			name: 'Latviski',
			tanslations: lvTranslations
		}
	}

	get lang() {
		return localStorage.getItem('lang') || 'en'
	}

	set lang(lang: string) {
		if (lang in this.availableLanguages) {
			localStorage.setItem('lang', lang)
		}
	}

	public get(key: string, ...params: string[]) {
		let str = key
		if (this.availableLanguages[this.lang].tanslations[key]) {
			str = this.availableLanguages[this.lang].tanslations[key]
		}
		return sprintf(str, ...params)
	}
}

const Lang = new Lang_()
export default Lang
