import { FormGroup, Slider } from '@blueprintjs/core'
import React from 'react'
import { pick } from '../../../../Util'

function percentLabelRenderer(val) {
	return val + '%'
}

export default class ProfileV1Editor extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.normalizeValues(
			pick(props.profile, [
				'daliSpeed',
				'motionTime',
				'sunriseOffset',
				'sunsetOffset',
				'dayBrightness',
				'nightBrightness',
				'nightBrightnessMotion',
				'nightBrightnessN1',
				'nightBrightnessN2',
				'nightBrightnessN3',
				'nightBrightnessN4',
				'nightBrightnessN5'
			])
		)
	}

	getData() {
		return this.state
	}

	normalizeValues(values) {
		const p = { ...values }
		p.daliSpeed = parseInt(p.daliSpeed, 10)
		p.motionTime = parseInt(p.motionTime, 10)
		p.sunriseOffset = parseInt(p.sunriseOffset, 10)
		p.sunsetOffset = parseInt(p.sunsetOffset, 10)
		p.dayBrightness = parseInt(p.dayBrightness, 10)
		p.nightBrightness = parseInt(p.nightBrightness, 10)
		p.nightBrightnessMotion = parseInt(p.nightBrightnessMotion, 10)
		p.nightBrightnessN1 = parseInt(p.nightBrightnessN1, 10)
		p.nightBrightnessN2 = parseInt(p.nightBrightnessN2, 10)
		p.nightBrightnessN3 = parseInt(p.nightBrightnessN3, 10)
		p.nightBrightnessN4 = parseInt(p.nightBrightnessN4, 10)
		p.nightBrightnessN5 = parseInt(p.nightBrightnessN5, 10)
		return p
	}

	setProfileValue(key, value) {
		const p = { ...this.state }
		p[key] = value
		this.setState({ ...p })
	}

	render() {
		const p = this.state
		return (
			<div className="ProfileV1Editor">
				<FormGroup label="Spožuma maiņas ātrums (DALI)">
					<Slider max={7} value={p.daliSpeed} onChange={(val) => this.setProfileValue('daliSpeed', val)} />
				</FormGroup>

				<FormGroup label="Kustības laiks" labelInfo="(sekundes)">
					<Slider max={600} value={p.motionTime} onChange={(val) => this.setProfileValue('motionTime', val)} labelStepSize={60} />
				</FormGroup>

				<FormGroup label="Saullēkta nobīde (+/-)" labelInfo="(minūtes)">
					<Slider
						min={-120}
						max={120}
						value={p.sunriseOffset}
						onChange={(val) => this.setProfileValue('sunriseOffset', val)}
						labelStepSize={30}
					/>
				</FormGroup>

				<FormGroup label="Saulrieta nobīde (+/-)" labelInfo="(minūtes)">
					<Slider
						min={-120}
						max={120}
						value={p.sunsetOffset}
						onChange={(val) => this.setProfileValue('sunsetOffset', val)}
						labelStepSize={30}
					/>
				</FormGroup>

				<div className="heading">Gaismas spožumi</div>

				<FormGroup label="Dienā">
					<Slider
						max={100}
						value={p.dayBrightness}
						onChange={(val) => this.setProfileValue('dayBrightness', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, bez satiksmes">
					<Slider
						max={100}
						value={p.nightBrightness}
						onChange={(val) => this.setProfileValue('nightBrightness', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, satiksmē">
					<Slider
						max={100}
						value={p.nightBrightnessMotion}
						onChange={(val) => this.setProfileValue('nightBrightnessMotion', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, satiksmē pie blakus staba">
					<Slider
						max={100}
						value={p.nightBrightnessN1}
						onChange={(val) => this.setProfileValue('nightBrightnessN1', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, satiksmē pie otrā blakus staba">
					<Slider
						max={100}
						value={p.nightBrightnessN2}
						onChange={(val) => this.setProfileValue('nightBrightnessN2', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, satiksmē pie trešā blakus staba">
					<Slider
						max={100}
						value={p.nightBrightnessN3}
						onChange={(val) => this.setProfileValue('nightBrightnessN3', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, satiksmē pie ceturtā blakus staba">
					<Slider
						max={100}
						value={p.nightBrightnessN4}
						onChange={(val) => this.setProfileValue('nightBrightnessN4', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>

				<FormGroup label="Naktī, satiksmē pie piektā blakus staba">
					<Slider
						max={100}
						value={p.nightBrightnessN5}
						onChange={(val) => this.setProfileValue('nightBrightnessN5', val)}
						labelRenderer={percentLabelRenderer}
						labelStepSize={25}
					/>
				</FormGroup>
			</div>
		)
	}
}
