/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const SendBackward: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="send-backward" ref={ref}  {...props}>
            <path
                d={isLarge ? "M214.142 5.858C206.332 -1.952 193.6684 -1.952 185.8578 5.858L105.8578 85.858C98.0474 93.668 98.0474 106.332 105.8578 114.142C113.6684 121.952 126.3316 121.952 134.1422 114.142L180 68.284L180 180L20 180C8.95432 180 -9.65646e-7 188.954 0 200C9.65646e-7 211.0458 8.95432 220 20 220L180 220V280L20 280C8.95432 280 0.00000602818 288.9544 0.00000699382 300L0.0000157361 360C0.00001670174 371.0456 8.95434 380 20 380L380 380C391.046 380 400 371.0456 400 360V300C400 288.9542 391.046 280 380 280L220 280V220L380 220C391.046 220 400 211.0456 400 200C400 188.954 391.046 180 380 180L220 180L220 68.284L265.858 114.142C273.668 121.952 286.332 121.952 294.142 114.142C301.952 106.332 301.952 93.668 294.142 85.858L214.142 5.858zM0 400H400V0H0V400z" : "M146.0588 5.8C149.6564 2.2 154.6534 0 160.05 0C165.4466 0 170.6434 2.2 174.2412 5.8L234.204 65.6C237.802 69.4 240 74.4 240 79.8C240 90.8 231.006 99.8 220.012 99.8C214.416 99.8 209.42 97.6 205.822 94L180.0374 68.2C180.0374 92.134 180 116.066 180 139.9994L300 139.9994C311.046 139.9994 320 148.9538 320 159.9994C320 171.0452 311.046 179.9994 300 179.9994L180 179.9994L180 219.9994L300 219.9994C311.046 219.9994 320 228.9538 320 239.9994L320 279.9994C320 291.0452 311.046 299.9994 300 299.9994L20 299.9994C8.95432 299.9994 0.000025444 291.0452 0.0000244784 279.9994L0.0000209814 239.9994C0.0000200158 228.9538 8.95432 219.9994 20 219.9994L140 219.9994L140 179.9994L20 179.9994C8.95432 179.9994 0.00001495324 171.0452 0.0000139876 159.9994C0.00001302194 148.9538 8.9543 139.9994 20 139.9994L140 139.9994L140 68.284L140.0624 68.284L140.0624 68.2L114.2786 94C110.6808 97.6 105.684 99.8 100.2874 99.8C89.2942 99.8 80.2998 90.8 80.2998 79.8C80.2998 74.2 82.4984 69.2 86.0962 65.6L146.0588 5.8z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
SendBackward.defaultProps = {
    size: IconSize.STANDARD,
};
SendBackward.displayName = `Blueprint5.Icon.SendBackward`;
export default SendBackward;
