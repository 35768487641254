/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LayoutTopRowTwoTiles: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="layout-top-row-two-tiles" ref={ref}  {...props}>
            <path
                d={isLarge ? "M0 380C0 391.0457 8.9543 400 20 400H160C171.0456 400 180 391.0457 180 380V240C180 228.9544 171.0456 220 160 220H20C8.9543 220 0 228.9544 0 240V380zM220 380C220 391.0457 228.954 400 240 400H380C391.046 400 400 391.0457 400 380V240C400 228.9544 391.046 220 380 220H240C228.954 220 220 228.9544 220 240V380zM0 160C0 171.046 8.9543 180 20 180H380C391.046 180 400 171.046 400 160V20C400 8.954 391.046 0 380 0H20C8.95432 0 0 8.954 0 20V160z" : "M200 180C188.9544 180 180 188.9544 180 200V300C180 311.0457 188.9544 320.000000965646 200 320L300 319.99999125772C311.046 319.99999029208 320 311.04568 320 300V200C320 188.9542 311.046 180 300 180L200 180zM20 180C8.9543 180 -9.65646e-7 188.9544 0 200L0.00000874228 300C0.00000970792 311.0457 8.95432 320.000000965646 20 320L120 319.99999125772C131.0458 319.99999029208 140 311.04568 140 300L140 200C140 188.9542 131.0456 180 120 180L20 180zM20 0C8.9543 0 -9.65646e-7 8.954 0 20L0.00000874228 120C0.00000970792 131.0456 8.95432 140 20 140L300 140C311.046 140 320 131.0456 320 120V20C320 8.954 311.046 0 300 0L20 0z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LayoutTopRowTwoTiles.defaultProps = {
    size: IconSize.STANDARD,
};
LayoutTopRowTwoTiles.displayName = `Blueprint5.Icon.LayoutTopRowTwoTiles`;
export default LayoutTopRowTwoTiles;
