/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LayoutTopRowThreeTiles: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="layout-top-row-three-tiles" ref={ref}  {...props}>
            <path
                d={isLarge ? "M0 160C0 171.046 8.9543 180 20 180H380C391.046 180 400 171.046 400 160V20C400 8.954 391.046 0 380 0H20C8.95432 0 0 8.954 0 20V160zM0 380C0 391.0457 8.9543 400 20 400H80C91.0456 400 100 391.0457 100 380V240C100 228.9544 91.0456 220 80 220H20C8.9543 220 0 228.9544 0 240V380zM140 380C140 391.0457 148.9544 400 160 400H240C251.046 400 260 391.0457 260 380V240C260 228.9544 251.046 220 240 220H160C148.9544 220 140 228.9544 140 240V380zM300 380C300 391.0457 308.954 400 320 400H380C391.046 400 400 391.0457 400 380V240C400 228.9544 391.046 220 380 220H320C308.954 220 300 228.9544 300 240V380z" : "M140 180C128.9544 180 120 188.9544 120 200L120 300C120 311.0457 128.9544 319.99999776872 140 319.999998251532L180 320C191.0456 320.000000482822 200 311.0457 200 300V200C200 188.9544 191.0456 180 180 180H140zM20 180C8.95432 180 0.00000485396 188.9544 0.00000437114 200L0 300C-4.82822e-7 311.0457 8.9543 319.99999776872 20 319.999998251532L60 320C71.0456 320.000000482822 80 311.0457 80 300V200C80 188.9544 71.0456 180 60 180H20zM260 180C248.954 180 240 188.9544 240 200V300C240 311.0457 248.954 319.99999776872 260 319.999998251532L300 320C311.046 320.000000482822 320 311.0457 320 300V200C320 188.9544 311.046 180 300 180H260zM20 0C8.95432 0 0.00000485396 8.954 0.00000437114 20L0 120C-4.82822e-7 131.0456 8.9543 140 20 140L300 140C311.046 140 320 131.0458 320 120V20C320 8.954 311.046 0 300 0L20 0z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LayoutTopRowThreeTiles.defaultProps = {
    size: IconSize.STANDARD,
};
LayoutTopRowThreeTiles.displayName = `Blueprint5.Icon.LayoutTopRowThreeTiles`;
export default LayoutTopRowThreeTiles;
