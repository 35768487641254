import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import './ChartGroup.scss'
import { DateTime } from 'luxon'
import { Button } from '@blueprintjs/core'

const ChartGroupContext = createContext(null)

export default function ChartGroup({ children, onChangeCurDate }) {
	const allHoursLabels = useMemo(() => {
		const now = DateTime.now()
		const start = now.startOf('day')

		let ret = [start]

		for (let i = 1; i <= 23; i++) {
			ret.push(start.plus({ hours: i }))
		}

		ret = [...ret, now.endOf('day')]

		return ret
	}, [])

	const [curDate, setCurDate] = useState(DateTime.now().startOf('day'))

	function adjustDays(num) {
		setCurDate((curDate) => curDate.plus({ days: num }))
	}

	useEffect(() => {
		onChangeCurDate?.(curDate)
	}, [curDate])

	return (
		<div className="chartGroup">
			<ChartGroupContext.Provider value={{ curDate, allHoursLabels }}>
				<div className="dateChanger">
					<Button small minimal icon="chevron-left" onClick={() => adjustDays(-1)} />
					<div className="value">{curDate.toFormat('yyyy-LL-dd')}</div>
					<Button
						small
						minimal
						icon="chevron-right"
						onClick={() => adjustDays(1)}
						disabled={curDate >= DateTime.now().startOf('day')}
					/>
				</div>
				<div className="charts">{children}</div>
				<div className="dateChanger">
					<Button small minimal icon="chevron-left" onClick={() => adjustDays(-1)} />
					<div className="value">{curDate.toFormat('yyyy-LL-dd')}</div>
					<Button
						small
						minimal
						icon="chevron-right"
						onClick={() => adjustDays(1)}
						disabled={curDate >= DateTime.now().startOf('day')}
					/>
				</div>
			</ChartGroupContext.Provider>
		</div>
	)
}

export const useChartGroupContext = () => useContext(ChartGroupContext)
