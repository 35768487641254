import './Page.scss'
import React from 'react'
import MainMenu from './MainMenu'

export default class Page extends React.Component {
	componentDidMount() {
		if (this.props.title) {
			document.title = this.props.title + ' - Idea Lights'
		} else {
			document.title = 'Idea Lights'
		}
		if (this.props.mobileViewport) {
			let meta = document.querySelector('meta[name="viewport"]')
			if (!meta) {
				meta = document.createElement('meta')
				meta.name = 'viewport'
				document.head.appendChild(meta)
			}
			meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
		} else {
			let meta = document.querySelector('meta[name="viewport"]')
			if (meta) {
				meta.remove()
			}
		}
	}

	componentWillUnmount() {
		document.title = 'Idea Lights'
	}

	render() {
		const className = ['Page']
		if (this.props.className) {
			className.push(this.props.className)
		}
		if (this.props.noPadding) {
			className.push('noPadding')
		}
		return (
			<>
				{!this.props.fullscreen && <MainMenu page={this.props} />}
				<div className={className.join(' ')}>
					<div className="PageContainer">{this.props.children}</div>
				</div>
			</>
		)
	}
}
