import './index.scss'

import React from 'react'
import API from '../../API'
import Lang from '../../Lang'
import Map from '../../Components/Map'
import Page from '../../Components/Page'
import DeviceList from './Components/DeviceList'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import DeviceDetails from './Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'
import { showContextMenu } from '@blueprintjs/popover2'
import ProfileDetails from './Components/ProfileDetails'
import { Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { profileTemplate } from './Components/ProfileDetails/ProfileV2Editor'
import { DateTime } from 'luxon'
import { isDeviceGateway } from '../../Util'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			devices: null,
			selectedDevice: null,
			searchingFor: ''
		}
		this.mapRef = React.createRef()
	}

	get map() {
		return this.mapRef.current.map
	}

	componentDidMount() {
		this.reload()
	}

	async loadProfiles() {
		const colors = ['#2D72D2', '#D33D17', '#238551', '#C87619', '#CD4246', '#CCCC66', '#66CCCC', '#BB66BB', '#663366', '#666633']
		let profiles = await API.call('LightingProfile.ListWithDevices')
		for (let i = 0; i < profiles.length; i++) {
			if (colors.length > 0) {
				profiles[i].color = colors.shift()
			} else {
				profiles[i].color = 'gray'
			}
		}

		return profiles
	}

	async reload(dontZoom = false) {
		let [devices, profiles] = await Promise.all([API.getDevices(), this.loadProfiles()])

		this.setState({ lightProfiles: profiles })

		let devicesWithoutProfile = []
		for (let dev of devices.devices) {
			let found = false
			for (let p of profiles) {
				if (p.deviceIds.indexOf(dev.id) != -1) {
					found = true
				}
			}
			if (!found) {
				devicesWithoutProfile.push(dev.id)
			}
		}
		if (devicesWithoutProfile.length > 0) {
			profiles = [
				{
					id: 0,
					title: Lang.get('No Profile'),
					deviceIds: devicesWithoutProfile,
					color: 'gray',
					disabled: true
				},
				...profiles
			]
		}
		this.setState({ profiles, devices }, () => {
			if (dontZoom) return

			for (let d of devices.devices) {
				if (!d.latitude || !d.longitude) {
					continue
				}
				const deviceCoord = [d.longitude, d.latitude]

				const markerElement = this.createMarkerElementForDevice(d)
				const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat(deviceCoord).addTo(this.map)
				markerElement.addEventListener('click', (e) => this.onDeviceSelect(d, { from: 'map' }))
				d.marker = marker
			}
			this.zoomDevices(devices.devices)
			this.updateAllMarkerColors()
		})
	}

	async selectProfile(profile) {
		this.setState({ selectedProfile: profile }, () => {
			this.map.resize()
		})

		const device = this.state.selectedDevice
		if (device) {
			device.marker?.getElement().classList.remove('selected')
		}
		await this.setState({ selectedDevice: null })
	}

	createProfile(args) {
		let newProfile = {
			id: 0,
			title: Lang.get('New Profile'),
			daliSpeed: 1,
			motionTime: 60,
			deviceIds: []
		}

		if (args.version == 1) {
			newProfile = {
				...newProfile,
				version: 1,
				sunriseOffset: 0,
				sunsetOffset: 0,
				dayBrightness: 0,
				nightBrightness: 70,
				nightBrightnessMotion: 100,
				nightBrightnessN1: 90,
				nightBrightnessN2: 80,
				nightBrightnessN3: 0,
				nightBrightnessN4: 0,
				nightBrightnessN5: 0
			}
		} else if (args.version == 2) {
			newProfile = {
				...newProfile,
				version: 2,
				title: Lang.get('New Profile'),
				...JSON.parse(JSON.stringify(profileTemplate))
			}
		}

		this.selectProfile(newProfile)
	}

	createMarkerElementForDevice(dev) {
		const markerElement = document.createElement('div')
		markerElement.className = 'Marker DeviceMarker'

		const iconElement = document.createElement('i')
		let deviceIcon = 'lightbulb'
		if (dev.customIcon) {
			deviceIcon = dev.customIcon
		}
		iconElement.className = 'fa-fw fa-duotone fa-' + deviceIcon
		markerElement.appendChild(iconElement)

		const labelElement = document.createElement('div')
		labelElement.className = 'label'
		labelElement.innerText = dev.id
		markerElement.appendChild(labelElement)

		markerElement.style.backgroundColor = 'gray'

		if (isDeviceGateway(dev)) {
			const gatewayIcon = document.createElement('i')
			gatewayIcon.className = 'gatewayIcon fa-fw fa-duotone fa-wifi'
			markerElement.appendChild(gatewayIcon)
		}

		// Right click handler
		markerElement.addEventListener('contextmenu', (e) => {
			e.preventDefault()

			let menuItems = [<MenuItem disabled={true} text={Lang.get('Device') + ' ' + dev.id} />]

			// Add or remove neighbour menu
			if (this.state.selectedDevice && this.state.selectedDevice.id != dev.id) {
				let neighbourValue = this.state.selectedDevice.neighbours.find((n) => n.id == dev.id)
				menuItems.push(<MenuDivider />)
				if (neighbourValue) {
					menuItems.push(
						<MenuItem
							icon="delete"
							text={Lang.get('Remove Neighbour')}
							onClick={() => this.setNeighbourMultiplier(this.state.selectedDevice, dev, 0)}
						/>
					)
				} else {
					menuItems.push(
						<MenuItem
							icon="add"
							text={Lang.get('Add Neighbour')}
							onClick={() => this.setNeighbourMultiplier(this.state.selectedDevice, dev, 1)}
						/>
					)
				}
			}

			// Profile menu
			{
				let profileMenuItems = []
				for (let p of this.state.profiles) {
					if (p.disabled) {
						continue
					}
					const selected = dev.lightingProfileId == p.id
					profileMenuItems.push(
						<MenuItem
							key={p.id}
							icon={selected ? 'tick' : 'blank'}
							text={p.title}
							onClick={async () => {
								// Ignore if profiles isn't changing
								if (dev.lightingProfileId == p.id) {
									return
								}

								const res = await API.call('LightingProfile.AddDevice', { deviceId: dev.id, profileId: p.id })
								if (!res || !res.success) {
									return
								}

								// Remove from previous profile
								if (dev.lightingProfileId) {
									const prevProfile = this.state.profiles.find((p) => p.id == dev.lightingProfileId)
									if (prevProfile) {
										prevProfile.deviceIds = prevProfile.deviceIds.filter((id) => id != dev.id)
									}
								}

								// Add to the new profile
								dev.lightingProfileId = p.id
								p.deviceIds.push(dev.id)

								// Update UI
								this.forceUpdate()
								this.updateAllMarkerColors()
							}}
						/>
					)
				}

				menuItems.push(<MenuItem text={Lang.get('Lighting Profile')}>{profileMenuItems}</MenuItem>)
			}

			showContextMenu({
				content: <Menu>{menuItems}</Menu>,
				targetOffset: { left: e.clientX, top: e.clientY }
			})
		})

		return markerElement
	}

	updateAllMarkerColors() {
		const now = new Date().getTime() / 1000
		for (let d of this.state.devices.devices) {
			if (!d.marker) continue
			const markerElement = d.marker.getElement()
			markerElement.style.backgroundColor = 'gray'

			if (this.mapRef.current.state.iconColors == 'lighting-profile') {
				for (let p of this.state.profiles) {
					if (p.deviceIds.indexOf(d.id) != -1) {
						markerElement.style.backgroundColor = p.color
					}
				}
			} else if (this.mapRef.current.state.iconColors == 'device-status') {
				const diff = now - d.lastDataTime
				if (diff < 60 * 15) {
					// 15 minutes
					markerElement.style.backgroundColor = '#32A467'
				} else if (diff < 60 * 60 * 24) {
					// 24 hours
					markerElement.style.backgroundColor = '#EC9A3C'
				} else {
					markerElement.style.backgroundColor = '#CD4246'
				}
			} else if (this.mapRef.current.state.iconColors == 'report-health') {
				const reportCount = d.reportHealth
				const healthPercentage = reportCount / 30
				markerElement.style.backgroundColor = valueToHexColor(healthPercentage)
			} else if (this.mapRef.current.state.iconColors == 'operative') {
				const diff = now - d.lastDataTime
				if (diff >= 60 * 60) {
					// No data for an hour or more -> gray
					markerElement.style.backgroundColor = '#CCCCCC'
				} else {
					const d4i = d.lastCommands.find((c) => c.commandName == 'D4I')
					if (d4i?.payload.ledCurrent > 0) {
						// Led current positive -> green
						markerElement.style.backgroundColor = '#32A467'
					} else {
						// Led current zero, or no D4I command -> yellow
						markerElement.style.backgroundColor = '#CCCC66'
					}
				}
			}
		}
	}

	async setNeighbourMultiplier(device, neighbour, multiplier) {
		const existing = device.neighbours.find((n) => n.id == neighbour.id)
		if (!existing && multiplier > 0) {
			device.neighbours.push({ id: neighbour.id, multiplier })
			this.updateNeighbourIndicators()
		} else if (existing && multiplier == 0) {
			device.neighbours = device.neighbours.filter((n) => n.id != neighbour.id)
			this.updateNeighbourIndicators()
		}

		let outValues = []
		for (let n of device.neighbours) {
			outValues.push({ id: n.id, multiplier: n.multiplier })
		}
		console.log('Device.SetNeighbours', { deviceId: device.id, neighbours: outValues })
		let res = await API.call('Device.SetNeighbours', { deviceId: device.id, neighbours: outValues })
		console.log('Device.SetNeighbours res', res)

		this.forceUpdate()
	}

	zoomDevices(devices) {
		const zoomBounds = new LngLatBounds()
		for (let d of devices) {
			if (!d.latitude || !d.longitude) {
				continue
			}
			const deviceCoord = [d.longitude, d.latitude]
			zoomBounds.extend(deviceCoord)
		}
		this.map.fitBounds(zoomBounds, { padding: 90, duration: 0 })
	}

	async onDeviceSelect(device, options) {
		if (this.state.selectedDevice) {
			this.state.selectedDevice.marker.getElement().classList.remove('selected')
		}
		if (device) {
			device.marker?.getElement().classList.add('selected')
		}
		this.setState({ selectedDevice: device, selectedProfile: null }, () => {
			this.map.resize()
			this.updateNeighbourIndicators()

			// Center selected device
			if (device && (options?.from == 'list' || options?.from == 'map' || this.props.inspector)) {
				const curZoom = this.map.getZoom()
				const targetZoom = options?.from == 'list' ? 18 : options?.from == 'map' ? 15 : 18

				const flyToOptions = {
					center: [device.longitude, device.latitude],
					...(curZoom < targetZoom && { zoom: targetZoom }),
					essential: true
				}
				if (this.props.inspector) {
					flyToOptions.padding = { bottom: document.querySelector('.DeviceDetails').offsetHeight }
				}
				this.map.flyTo(flyToOptions)
			}
		})
	}

	updateNeighbourIndicators() {
		const device = this.state.selectedDevice

		if (!device) {
			this.map.removeLayer('neighbour-lines-layer')

			if (this.map.isSourceLoaded('neighbour-lines')) {
				this.map.removeSource('neighbour-lines')
			}
			return
		}

		const linesData = {
			type: 'FeatureCollection',
			features: []
		}

		for (let n of device.neighbours) {
			const neighbour = this.state.devices.devices.find((d) => d.id == n.id)
			if (!neighbour) {
				continue
			}
			if (!neighbour.latitude || !neighbour.longitude) {
				continue
			}
			const neighbourCoord = [neighbour.longitude, neighbour.latitude]
			linesData.features.push({
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [
						[device.longitude, device.latitude],
						[neighbour.longitude, neighbour.latitude]
					]
				}
			})
		}

		const source = this.map.getSource('neighbour-lines')

		if (source) {
			source.setData(linesData)
		} else {
			this.map.addSource('neighbour-lines', {
				type: 'geojson',
				data: linesData
			})

			this.map.addLayer({
				type: 'line',
				source: 'neighbour-lines',
				id: 'neighbour-lines-layer',
				paint: {
					'line-color': '#ff6600',
					'line-width': 4,
					'line-opacity': 0.6
				}
			})
		}
	}

	mapViewStateChange(newState) {
		this.updateAllMarkerColors()
	}

	render() {
		return (
			<Page
				title={Lang.get('Devices')}
				icon="fa-duotone fa-router"
				className={'DevicesPage' + (this.props.inspector ? ' inspector' : '')}
				noPadding
				fullscreen={this.props.inspector}
				mobileViewport={this.props.inspector}>
				{this.state.devices == null && <Placeholder loading label={Lang.get('Loading devices') + ' ...'} />}
				{this.state.devices && (
					<>
						<div className="container sideBar">
							<div className="topBar">
								<SearhInput value={this.state.searchingFor} onChange={(e) => this.setState({ searchingFor: e.target.value })} />
								<Button
									text={Lang.get('Create New Profile')}
									icon="add"
									minimal
									intent="primary"
									onClick={() => this.createProfile({ version: 2 })}
								/>
							</div>
							<DeviceList
								devices={this.state.devices}
								lightProfiles={this.state.lightProfiles}
								view={this}
								searchingFor={this.state.searchingFor}
							/>
						</div>
						{this.state.selectedDevice && <DeviceDetails device={this.state.selectedDevice} view={this} />}
						{this.state.selectedProfile && (
							<ProfileDetails
								profile={this.state.selectedProfile}
								view={this}
								key={this.state.selectedProfile.id + '-' + this.state.selectedProfile.version}
							/>
						)}
						<div className="MapContainer">
							<Map ref={this.mapRef} onViewStateChange={(newState) => this.mapViewStateChange(newState)} />
						</div>
					</>
				)}
			</Page>
		)
	}
}

function SearhInput(props) {
	return (
		<div className="SearchInput">
			<i className="fa-fw fa-duotone fa-search" />
			<input type="text" placeholder={Lang.get('Search...')} value={props.value} onChange={props.onChange} />
		</div>
	)
}

function valueToHexColor(value) {
	if (value < 0) value = 0
	if (value > 1) value = 1

	// Interpolate between red and yellow, and yellow and green
	let r, g

	if (value <= 0.5) {
		// Interpolating between red (255, 0, 0) and yellow (255, 255, 0)
		r = 255
		g = Math.floor(255 * (value / 0.5))
	} else {
		// Interpolating between yellow (255, 255, 0) and green (0, 255, 0)
		r = Math.floor(255 * ((1 - value) / 0.5))
		g = 255
	}

	const toHex = (n) => {
		let hex = n.toString(16)
		return hex.length === 1 ? '0' + hex : hex
	}

	return `#${toHex(r)}${toHex(g)}00`
}
