import './index.scss'

import React from 'react'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import API from '../../API'
import Lang from '../../Lang'
import Map from '../../Components/Map'
import Page from '../../Components/Page'
import { DateTime } from 'luxon'
import DeviceDetails from '../Devices/Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			faults: null,
			selectedDevice: null
		}
		this.mapRef = React.createRef()

		this.onDeviceSelect = this.onDeviceSelect.bind(this)
	}

	get map() {
		return this.mapRef.current.map
	}

	async componentDidMount() {
		const faults = await API.call('Faults.Get')
		console.log(faults)
		this.setState({ faults }, () => {
			const devices = []
			for (let f of faults) {
				const d = f.device
				if (!d.latitude || !d.longitude) {
					continue
				}
				if (f.ended) {
					continue
				}
				devices.push(d)
				const deviceCoord = [d.longitude, d.latitude]
				const markerElement = this.createMarkerElementForDevice(d)
				const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat(deviceCoord).addTo(this.map)
				markerElement.addEventListener('click', (e) => this.onDeviceSelect(d, { from: 'map' }))
				d.marker = marker
			}
			this.zoomDevices(devices)
		})
	}

	onDeviceSelect(device, options) {
		if (this.state.selectedDevice) {
			this.state.selectedDevice.marker?.getElement().classList.remove('selected')
		}

		device?.marker?.getElement().classList.add('selected')

		this.setState({ selectedDevice: device }, () => {
			if (!device?.marker) return
			this.map.resize()

			// Center selected device
			if (options?.from == 'list' || options?.from == 'map') {
				const flyToOptions = {
					center: [device.longitude, device.latitude],
					zoom: options?.from == 'list' ? 18 : options?.from == 'map' ? 15 : 18,
					essential: true
				}
				this.map.flyTo(flyToOptions)
			}
		})
	}

	createMarkerElementForDevice(dev) {
		const markerElement = document.createElement('div')
		markerElement.className = 'Marker DeviceMarker'

		const iconElement = document.createElement('i')
		iconElement.className = 'fa-fw fa-duotone fa-triangle-exclamation'
		markerElement.appendChild(iconElement)

		const labelElement = document.createElement('div')
		labelElement.className = 'label'
		labelElement.innerText = dev.id
		markerElement.appendChild(labelElement)

		return markerElement
	}

	zoomDevices(devices) {
		if (devices.length == 0) {
			return
		}
		const zoomBounds = new LngLatBounds()
		for (let d of devices) {
			if (!d.latitude || !d.longitude) {
				continue
			}
			const deviceCoord = [d.longitude, d.latitude]
			zoomBounds.extend(deviceCoord)
		}
		this.map.fitBounds(zoomBounds, { padding: 90, duration: 0 })
	}

	render() {
		let content = null
		if (!this.state.faults) {
			content = <div className="Loading">{Lang.get('Loading')}...</div>
		} else {
			if (this.state.faults.length === 0) {
				content = Lang.get('There are no faults at the moment.')
			} else {
				const currentFaults = this.state.faults.filter((fault) => fault.ended === null)
				const previousFaults = this.state.faults.filter((fault) => fault.ended !== null)
				content = (
					<>
						<FaultsList title={Lang.get('Ongoing Faults')} faults={currentFaults} onDeviceSelect={this.onDeviceSelect} />
						<FaultsList
							title={Lang.get('Resolved Faults')}
							faults={previousFaults}
							onDeviceSelect={this.onDeviceSelect}
							defaultCollapsed
						/>
					</>
				)
			}
		}
		return (
			<Page title={Lang.get('Faults')} icon="fa-duotone fa-triangle-exclamation" className="FaultsPage" noPadding>
				{this.state.faults == null && <Placeholder loading label={Lang.get('Loading faults') + ' ...'} />}
				{this.state.faults && (
					<>
						<div className="SideContainer">{content}</div>
						{this.state.selectedDevice && <DeviceDetails device={this.state.selectedDevice} view={this} />}
						<div className="MapContainer">
							<Map ref={this.mapRef} />
						</div>
					</>
				)}
			</Page>
		)
	}
}

function FaultsList(props) {
	if (props.faults.length == 0) {
		return null
	}
	const [collapsed, setCollapsed] = React.useState(props.defaultCollapsed)
	let faultsToShow = props.faults
	if (collapsed) {
		faultsToShow = []
	}
	return (
		<div className="FaultsList">
			<div className="header" onClick={() => setCollapsed(!collapsed)}>
				<i className={'fa-fw expander fa-duotone ' + (collapsed ? 'fa-chevron-right' : 'fa-chevron-down')} />
				<span>
					{props.title} ({props.faults.length})
				</span>
			</div>
			{faultsToShow.map((fault, i) => {
				const t = DateTime.fromISO(fault.started)
				let duration = null
				if (fault.ended) {
					duration = DateTime.fromISO(fault.ended).diff(t)
				} else {
					duration = DateTime.now().diff(t)
				}

				// 14 minutes or 3 hours or 10 days
				if (duration.as('minutes') < 60) {
					duration = duration.toFormat('mm') + ' ' + Lang.get('minutes')
				} else if (duration.as('hours') < 24) {
					duration = duration.toFormat('hh') + ' ' + Lang.get('hours')
				} else {
					duration = duration.toFormat('dd') + ' ' + Lang.get('days')
				}
				if (duration[0] === '0') {
					duration = duration.slice(1)
				}

				return (
					<div
						className="Fault"
						key={`fault-${i}${fault.started}`}
						onClick={() => props.onDeviceSelect(fault.device, { from: 'list' })}>
						<div className="icon">
							<i className="fa-duotone fa-triangle-exclamation" />
						</div>
						<div className="details">
							<div className="deviceId">
								{fault.device.id} - <span className="type">{Lang.get('Unreacheable')}</span>
							</div>

							<div className="from">
								<strong>{Lang.get('From')}:</strong>
								{t.toFormat('dd.LL.yy HH:mm')}
							</div>
							{fault.ended && (
								<div className="to">
									<strong>{Lang.get('To')}:</strong>
									{DateTime.fromISO(fault.ended).toFormat('dd.LL.yy HH:mm')}
								</div>
							)}
							<div className="duration">
								<strong>{Lang.get('Duration')}:</strong>
								{duration}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}
