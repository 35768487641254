/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const LayoutBottomRowThreeTiles: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="layout-bottom-row-three-tiles" ref={ref}  {...props}>
            <path
                d={isLarge ? "M0 380C0 391.0457 8.9543 400 20 400H380C391.046 400 400 391.0457 400 380V240C400 228.9544 391.046 220 380 220H20C8.95432 220 0 228.9544 0 240V380zM0 160C0 171.046 8.9543 180 20 180H80C91.0456 180 100 171.046 100 160V20C100 8.954 91.0456 0 80 0H20C8.9543 0 0 8.954 0 20V160zM140 160C140 171.046 148.9544 180 160 180H240C251.046 180 260 171.046 260 160V20C260 8.954 251.046 0 240 0H160C148.9544 0 140 8.954 140 20V160zM300 160C300 171.046 308.954 180 320 180H380C391.046 180 400 171.046 400 160V20C400 8.954 391.046 0 380 0H320C308.954 0 300 8.954 300 20V160z" : "M140 140C128.9544 140 120 131.0456 120 120L120 20C120 8.954 128.9544 0 140 0H180C191.0456 0 200 8.954 200 20V120C200 131.0456 191.0456 140 180 140H140zM20 140C8.95432 140 0.00000485396 131.0456 0.00000437114 120L0 20C-4.82822e-7 8.954 8.9543 0 20 0H60C71.0456 0 80 8.954 80 20V120C80 131.0456 71.0456 140 60 140H20zM260 140C248.954 140 240 131.0456 240 120V20C240 8.954 248.954 0 260 0H300C311.046 0 320 8.954 320 20V120C320 131.0456 311.046 140 300 140H260zM20 320C8.95432 320.000000482822 0.00000485396 311.0457 0.00000437114 300L0 200C-4.82822e-7 188.9544 8.9543 180 20 180L300 180C311.046 180 320 188.9542 320 200V300C320 311.04568 311.046 319.99998727798 300 319.9999877608L20 320z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
LayoutBottomRowThreeTiles.defaultProps = {
    size: IconSize.STANDARD,
};
LayoutBottomRowThreeTiles.displayName = `Blueprint5.Icon.LayoutBottomRowThreeTiles`;
export default LayoutBottomRowThreeTiles;
