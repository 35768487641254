/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Output: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="output" ref={ref}  {...props}>
            <path
                d={isLarge ? "M0 200C0 89.8 89.8 0 200 0C223.4 0 246.4 4 268.4 12C278.8 15.8 284.2000000000001 27.2 280.4 37.6C276.6 48 265.2 53.4 254.8 49.6C237.2 43.2 218.8 40 200 40C111.8 40 40 111.8 40 200C40 288.2 111.8 360 200 360C218.8 360 237.2 356.8 254.8 350.4C265.2 346.6 276.6 352 280.4 362.4C284.2000000000001 372.8 278.8 384.2 268.4 388C246.4 396 223.4 400 200 400C89.8 400 0 310.2 0 200zM394.2000000000001 214.2L314.2000000000001 294.2C310.6 297.8 305.6 300 300 300C289 300 280 291 280 280C280 274.4 282.2 269.4 285.8 265.8L331.8 220H100C88.9544 220 80 211.0456 80 200C80 188.954 88.9544 180 100 180H331.8L286 134.2000000000001C282.2 130.6 280 125.6 280 120C280 109 289 100 300 100C305.6 100 310.6 102.2 314.2000000000001 105.8L394.2000000000001 185.8C397.8 189.4 400 194.4 400 200C400 205.6 397.8 210.6 394.2000000000001 214.2z" : "M160 0C71.8 0 0 71.644 0 160.1C0 248.5554 71.8 320 160 320C178.8 320 197.2 316.798 214.8 310.394C225.2 306.59162 230.6 295.1844 226.8 284.778C223 274.3714 211.6 268.9682 201.2 272.7704C188 277.5734 174.2 279.975 160.2 279.975C94 279.975 40.2 226.1414 40.2 159.9C40.2 93.658 94 39.824 160.2 39.824C174.2 39.824 188 42.226 201.2 47.03C211.6 50.832 223 45.428 226.8 35.022C230.6 24.616 225.2 13.208 214.8 9.406C197.2 3.002 178.8 0 160 0zM314.2000000000001 174.1088C317.8 170.5066 320 165.5034 320 159.9C320 154.2964 317.8 149.2934 314.2000000000001 145.691L254.2 85.654C250.6 82.052 245.6 79.85 240 79.85C229 79.85 220 88.856 220 99.862C220 105.466 222.2 110.47 226 114.072L251.8 139.8874H100C89 139.8874 80 148.893 80 159.9C80 170.9068 89 179.9124 100 179.9124H251.8L225.8 205.7286C222.2 209.3308 220 214.334 220 219.9374C220 230.9444 229 239.95 240 239.95C245.6 239.95 250.6 237.7486 254.2 234.1464L314.2000000000001 174.1088z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Output.defaultProps = {
    size: IconSize.STANDARD,
};
Output.displayName = `Blueprint5.Icon.Output`;
export default Output;
