import { useEffect, useState } from 'react'
import Lang from '../../../Lang'
import { Button, Classes, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

export default function DeviceFilter({ profiles, onChange }) {
	const [activeDeviceIDs, setActiveDeviceIDs] = useState([])
	const [searchDeviceID, setSearchDeviceID] = useState('')
	const [searchMsg, setSearchMsg] = useState('')

	function toggleDevice(id) {
		if (!activeDeviceIDs.includes(id)) {
			setActiveDeviceIDs((cur) => [...cur, id])
		} else {
			setActiveDeviceIDs((cur) => cur.filter((cID) => cID != id))
		}
	}

	function toggleProfile(profileId) {
		const idArr = profiles.find((p) => p.id === profileId).deviceIds
		const allActive = idArr.every((id) => activeDeviceIDs.includes(id))

		if (!allActive) {
			setActiveDeviceIDs((cur) => [...new Set([...cur, ...idArr])])
		} else {
			setActiveDeviceIDs((cur) => cur.filter((el) => !idArr.includes(el)))
		}
	}

	function handleSearch() {
		const validIds = profiles.map((p) => p.deviceIds).flat(1)
		if (isNaN(searchDeviceID) || !validIds.includes(parseInt(searchDeviceID))) {
			setSearchMsg(Lang.get('Invalid ID'))
			return
		}

		toggleDevice(parseInt(searchDeviceID))
		setSearchDeviceID('')
		setSearchMsg('')
	}

	const clearDevices = () => setActiveDeviceIDs([])

	useEffect(() => {
		onChange(activeDeviceIDs)
	}, [activeDeviceIDs])

	const menu = (
		<Menu className="scrollable">
			<MenuItem
				text={Lang.get('All Devices')}
				icon={activeDeviceIDs.length === 0 ? 'tick' : 'blank'}
				shouldDismissPopover={false}
				onClick={clearDevices}
			/>
			<MenuDivider title={Lang.get('Search...')} />
			<MenuItem
				shouldDismissPopover={false}
				text={
					<div className="search-box">
						{searchMsg && <span>{searchMsg}</span>}
						<div>
							<Button icon="search" onClick={handleSearch} />
							<input
								placeholder={Lang.get('Device ID')}
								value={searchDeviceID}
								onChange={(e) => setSearchDeviceID(e.target.value)}
							/>
						</div>
					</div>
				}
			/>
			<MenuDivider title={Lang.get('Lighting Profiles')} />
			{profiles &&
				profiles.map((p) => {
					const getIcon = () => {
						if (p.deviceIds.length > 0 && p.deviceIds.every((id) => activeDeviceIDs.includes(id))) return 'tick'

						const hasSome = p.deviceIds.find((id) => activeDeviceIDs.includes(id)) != undefined
						if (hasSome) return 'dot'

						return 'blank'
					}

					return (
						<MenuItem
							key={`lp-${p.id}`}
							text={p.title}
							icon={getIcon()}
							onClick={() => toggleProfile(p.id)}
							shouldDismissPopover={false}
							submenuProps={{
								className: 'scrollable'
							}}>
							{p.deviceIds.map((i) => {
								return (
									<MenuItem
										icon={activeDeviceIDs.includes(i) ? 'tick' : 'blank'}
										text={i}
										key={`lp-i-${i}`}
										onClick={() => toggleDevice(i)}
										shouldDismissPopover={false}
									/>
								)
							})}
						</MenuItem>
					)
				})}
		</Menu>
	)

	return (
		<Popover2 content={menu} position="bottom-right">
			<Button text={Lang.get('Filter')} icon="filter" rightIcon="caret-down" />
		</Popover2>
	)
}
