import React from 'react'
import { DateTime } from 'luxon'
import Lang from '../../../../../Lang'
import { DateInput2 } from '@blueprintjs/datetime2'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Button, ButtonGroup, InputGroup, Switch, Menu, MenuItem, NumericInput } from '@blueprintjs/core'

// ===================================================================

const conditionTypes = [
	{
		type: 'weekdays',
		label: Lang.get('Weekdays'),
		icon: 'fa-calendar-clock',
		incompatibleWith: ['weekdays'],
		defaultValue: []
	},
	{
		type: 'nightlight',
		label: Lang.get('Nightlight'),
		icon: 'fa-moon',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'daylight',
		label: Lang.get('Daylight'),
		icon: 'fa-sun',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'date',
		label: Lang.get('Date'),
		icon: 'fa-calendar',
		incompatibleWith: ['date'],
		defaultValue: DateTime.now().toFormat('yyyy-LL-dd')
	}
]

// ===================================================================

export default class ConditionBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = { ...props.data }
		this.blockRef = props.data
	}

	componentDidUpdate() {
		for (let k in this.state) {
			this.blockRef[k] = this.state[k]
		}
	}

	setConditionBlockValue(key, value) {
		this.state.conditions[key] = value
		this.forceUpdate()
	}

	setValue(key, value) {
		this.setState({ [key]: value })
	}

	addCondition(conditionType) {
		const condition = conditionTypes.find((c) => c.type == conditionType)
		if (!condition) {
			return
		}
		if (condition.incompatibleWith.some((c) => this.state.conditions[c])) {
			alert(Lang.get('This condition is incompatible with existing conditions'))
			return
		}
		this.setConditionBlockValue(condition.type, condition.defaultValue)
	}

	removeCondition(conditionType) {
		delete this.state.conditions[conditionType]
		this.forceUpdate()
	}

	render() {
		const menu = (
			<Menu>
				{conditionTypes.map((c, i) => (
					<MenuItem
						key={`cbct-${c.label}${i}`}
						icon={<i className={`fa-fw fa-solid ${c.icon}`} />}
						onClick={() => this.addCondition(c.type)}
						text={c.label}
					/>
				))}
			</Menu>
		)

		const hasConditions = Object.keys(this.state.conditions).length > 0

		return (
			<div className="ConditionBlock">
				<div className="header">
					<div className="padded">
						<Switch
							label={Lang.get('Active')}
							checked={this.state.active}
							onChange={(e) => this.setState({ active: e.target.checked })}
						/>
						<InputGroup
							small
							placeholder={Lang.get('Note')}
							value={this.state.comment}
							onChange={(e) => this.setState({ comment: e.target.value })}
						/>
						<div>
							<ButtonGroup>
								<Tooltip2 content={Lang.get('Duplicate')}>
									<Button
										small
										minimal
										icon={<i className="fa-light fa-clone"></i>}
										onClick={() => this.props.conditionBlockAction('duplicate')}
									/>
								</Tooltip2>
								<Button
									small
									minimal
									icon={<i className="fa-solid fa-caret-up"></i>}
									onClick={() => this.props.conditionBlockAction('move-up')}
								/>
								<Button
									small
									minimal
									icon={<i className="fa-solid fa-caret-down"></i>}
									onClick={() => this.props.conditionBlockAction('move-down')}
								/>
							</ButtonGroup>
							<Button
								small
								minimal
								intent="danger"
								icon={<i className="fa-solid fa-xmark"></i>}
								onClick={() => this.props.conditionBlockAction('delete')}
							/>
						</div>
					</div>
				</div>
				<div className="padded">
					<div className="conditions">
						<h4>
							{Lang.get('Conditions')}
							<Popover2 content={menu} placement="bottom">
								<Button small minimal intent="success" icon={<i className="fa-solid fa-plus"></i>} />
							</Popover2>
						</h4>

						{hasConditions &&
							Object.keys(this.state.conditions).map((conditionType) => {
								const data = this.state.conditions[conditionType]
								return (
									<Condition
										block={this}
										conditionType={conditionType}
										data={data}
										key={`cb-${conditionType}`}
										onRemove={() => {
											this.removeCondition(conditionType)
										}}
									/>
								)
							})}

						{!hasConditions && <div className="noConditions">{Lang.get('No conditions')}</div>}
					</div>
				</div>
				<div className="padded">
					<h4>
						{Lang.get('Brightness levels')}
						<div className="DayBrightnessEditorLegend">
							<div className="idle">{Lang.get('No Motion')}</div>
							<div className="active">{Lang.get('Motion')}</div>
						</div>
					</h4>
					<DayBrightnessEditor block={this} />
				</div>
			</div>
		)
	}
}

// ===================================================================

class DayBrightnessEditor extends React.Component {
	constructor(props) {
		super(props)

		this.periodsRef = React.createRef()

		this.state = {
			periods: props.block.state.brightnessLevels
		}
	}

	timeToPercentage(time) {
		return (time / 86400) * 100
	}

	timeToString(time) {
		let t = DateTime.now().set({ hour: 0, minute: 0, second: time })
		return t.toFormat('HH:mm')
	}

	infoForEvent(e) {
		const periodsBounds = this.periodsRef.current.getBoundingClientRect()

		const xPercentage = ((e.clientX - periodsBounds.left) / periodsBounds.width) * 100

		let yPercentage = ((e.clientY - periodsBounds.top) / periodsBounds.height) * 100
		yPercentage = Math.max(0, Math.min(100, yPercentage))
		yPercentage = 100 - yPercentage

		let period = null
		let periodIndex = 0
		for (let p of this.state.periods) {
			const start = this.timeToPercentage(p.start)
			const end = this.timeToPercentage(p.start + p.duration)
			if (start <= xPercentage && xPercentage <= end) {
				period = p
				break
			}
			periodIndex++
		}

		return { period, periodIndex, xPercentage, yPercentage }
	}

	onMouseDown(e) {
		const info = this.infoForEvent(e)
		this.targetInfo = info

		if (e.target.classList.contains('activeBrightnessHandle')) {
			this.mode = 'activeBrightness'
		} else if (e.target.classList.contains('idleBrightnessHandle')) {
			this.mode = 'idleBrightness'
		} else if (e.target.classList.contains('horizontalResizeHandle')) {
			this.mode = 'horizontalResize'
		}
	}

	onMouseUp(e) {
		this.mode = null
	}

	onMouseMove(e) {
		if (this.mode && this.targetInfo) {
			const info = this.infoForEvent(e)
			if (this.mode == 'activeBrightness') {
				this.targetInfo.period.activeBrightness = Math.round(info.yPercentage) / 100
				// active brightness should not be less than idle brightness
				if (this.targetInfo.period.activeBrightness < this.targetInfo.period.idleBrightness) {
					this.targetInfo.period.idleBrightness = this.targetInfo.period.activeBrightness
				}
			} else if (this.mode == 'idleBrightness') {
				this.targetInfo.period.idleBrightness = Math.round(info.yPercentage) / 100
				// active brightness should not be less than idle brightness
				if (this.targetInfo.period.activeBrightness < this.targetInfo.period.idleBrightness) {
					this.targetInfo.period.activeBrightness = this.targetInfo.period.idleBrightness
				}
			} else if (this.mode == 'horizontalResize') {
				const duration = info.xPercentage - this.timeToPercentage(this.targetInfo.period.start)
				this.targetInfo.period.duration = Math.round((duration / 100) * 86400)

				const nextPeriod = this.state.periods[this.targetInfo.periodIndex + 1]
				if (nextPeriod) {
					nextPeriod.start = this.targetInfo.period.start + this.targetInfo.period.duration

					const nextNextPeriod = this.state.periods[this.targetInfo.periodIndex + 2]
					if (nextNextPeriod) {
						nextPeriod.duration = nextNextPeriod.start - nextPeriod.start
					} else {
						nextPeriod.duration = 86400 - nextPeriod.start
					}
				}
			}
			this.updated()
		}
	}

	deletePeriod(period) {
		if (this.state.periods.length == 1) {
			return
		}
		const index = this.state.periods.indexOf(period)
		const nextPeriod = this.state.periods[index + 1]
		if (nextPeriod) {
			nextPeriod.start = period.start
			const nextNextPeriod = this.state.periods[index + 2]
			if (nextNextPeriod) {
				nextPeriod.duration = nextNextPeriod.start - nextPeriod.start
			} else {
				nextPeriod.duration = 86400 - nextPeriod.start
			}
		} else {
			const prevPeriod = this.state.periods[index - 1]
			if (prevPeriod) {
				prevPeriod.duration = 86400 - prevPeriod.start
			}
		}
		this.state.periods.splice(index, 1)
		this.updated()
	}

	splitPeriod(period) {
		const index = this.state.periods.indexOf(period)
		const newPeriod = {
			start: period.start + Math.round(period.duration / 2),
			duration: Math.round(period.duration / 2),
			idleBrightness: period.idleBrightness,
			activeBrightness: period.activeBrightness
		}
		this.state.periods.splice(index + 1, 0, newPeriod)
		period.duration = Math.round(period.duration / 2)
		this.updated()
	}

	updated() {
		this.props.block.setValue('brightnessLevels', this.state.periods)
		// this.forceUpdate()
	}

	render() {
		return (
			<div
				className="DayBrightnessEditor"
				onMouseDown={(e) => this.onMouseDown(e)}
				onMouseUp={(e) => this.onMouseUp(e)}
				onMouseMove={(e) => this.onMouseMove(e)}>
				<div className="periods" ref={this.periodsRef}>
					{this.state.periods.map((period, i) => (
						<div
							key={`pp-${i}`}
							className="period"
							style={{ width: this.timeToPercentage(period.duration) + '%', left: this.timeToPercentage(period.start) + '%' }}>
							<div className="activeValueSlider" style={{ height: period.activeBrightness * 100 + '%' }}>
								<div className="handle activeBrightnessHandle">{Math.round(period.activeBrightness * 100)}%</div>
							</div>
							<div className="idleValueSlider" style={{ height: period.idleBrightness * 100 + '%' }}>
								<div className="handle idleBrightnessHandle">{Math.round(period.idleBrightness * 100)}%</div>
							</div>
							<div className="horizontalResizeHandle" />
						</div>
					))}
				</div>
				<div className="tickers">
					{this.state.periods.map((period, i) => (
						<React.Fragment key={`tp-${i}`}>
							<div
								className={'ticker' + (this.targetInfo?.periodIndex == i - 1 ? ' active' : '')}
								style={{ left: this.timeToPercentage(period.start) + '%' }}>
								{this.timeToString(period.start)}
							</div>
							<div
								className="tickerActionWrap"
								style={{ left: this.timeToPercentage(period.start) + '%', width: this.timeToPercentage(period.duration) + '%' }}>
								<div className="tickerAction">
									<ButtonGroup>
										<Button
											small
											minimal
											intent="success"
											icon={<i className="fa-solid fa-plus"></i>}
											onClick={() => this.splitPeriod(period)}
										/>
										<Button
											small
											minimal
											intent="danger"
											icon={<i className="fa-solid fa-minus"></i>}
											onClick={() => this.deletePeriod(period)}
										/>
									</ButtonGroup>
								</div>
							</div>
						</React.Fragment>
					))}
					<div className="ticker" style={{ left: this.timeToPercentage(86400) + '%' }}>
						00:00
					</div>
				</div>
			</div>
		)
	}
}

// ===================================================================

function Condition(props) {
	let condition = conditionTypes.find((c) => c.type == props.conditionType)
	if (!condition) {
		condition = { label: props.conditionType, icon: 'fa-question' }
	}
	let valueEl = null
	if (props.conditionType == 'weekdays') {
		const value = props.block.state.conditions.weekdays
		const abriviations = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun'.split(',')
		valueEl = (
			<ButtonGroup>
				<WeekdayConditionButton block={props.block} value={value} numValue={1} label={abriviations[0]} />
				<WeekdayConditionButton block={props.block} value={value} numValue={2} label={abriviations[1]} />
				<WeekdayConditionButton block={props.block} value={value} numValue={3} label={abriviations[2]} />
				<WeekdayConditionButton block={props.block} value={value} numValue={4} label={abriviations[3]} />
				<WeekdayConditionButton block={props.block} value={value} numValue={5} label={abriviations[4]} />
				<WeekdayConditionButton block={props.block} value={value} numValue={6} label={abriviations[5]} />
				<WeekdayConditionButton block={props.block} value={value} numValue={7} label={abriviations[6]} />
			</ButtonGroup>
		)
	} else if (props.conditionType == 'date') {
		const value = props.block.state.conditions.date
		valueEl = (
			<DateInput2
				className="bp5-small"
				value={value}
				onChange={(newVal) => props.block.setConditionBlockValue('date', newVal)}
				parseDate={(date) => null}
				formatDate={(date) => DateTime.fromJSDate(date).toFormat('yyyy-LL-dd')}
				highlightCurrentDay={true}
			/>
		)
	} else if (props.conditionType == 'daylight' || props.conditionType == 'nightlight') {
		const value = props.block.state.conditions[props.conditionType]
		valueEl = (
			<>
				<div className="lineBreak">
					<NumericInput
						small
						className="smallNumeric"
						value={value.sunriseOffset}
						onValueChange={(newVal) =>
							props.block.setConditionBlockValue(props.conditionType, { ...value, sunriseOffset: newVal })
						}
					/>
					<span className="small">saullēkta nobīde</span>
				</div>
				<div className="lineBreak">
					<NumericInput
						small
						className="smallNumeric"
						value={value.sunsetOffset}
						onValueChange={(newVal) =>
							props.block.setConditionBlockValue(props.conditionType, { ...value, sunsetOffset: newVal })
						}
					/>
					<span className="small">saulrieta nobīde</span>
				</div>
			</>
		)
	} else {
		valueEl = <pre>{JSON.stringify(props.data, null, 2)}</pre>
	}
	return (
		<div className="Condition">
			<div className="icon">
				<i className={`fa-fw fa-solid ${condition.icon}`}></i>
			</div>
			<div className="label">{condition.label}</div>
			<div className="value">{valueEl}</div>

			<div className="removeButton">
				<Button small minimal intent="danger" icon={<i className="fa-solid fa-xmark"></i>} onClick={() => props.onRemove()} />
			</div>
		</div>
	)
}

// ===================================================================

function WeekdayConditionButton(props) {
	const value = props.value
	const numValue = props.numValue
	const isActive = value.includes(numValue)
	return (
		<Button
			small
			intent={isActive ? 'primary' : undefined}
			onClick={() =>
				props.block.setConditionBlockValue('weekdays', isActive ? value.filter((v) => v != numValue) : [...value, numValue])
			}>
			{props.label}
		</Button>
	)
}

// ===================================================================
