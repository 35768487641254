import './index.scss'

import React from 'react'
import { DateTime } from 'luxon'
import Page from '../../../Components/Page'
import API, { RFCommand, RFPacket } from '../../../API'
import { Button, FormGroup, H5, InputGroup } from '@blueprintjs/core'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			connections: API.connections,
			selectedAgentId: 0
		}
	}

	componentDidMount() {
		API.addMessageListener(this, 'agent-list', (connections) => {
			this.setState({ connections: connections.args })
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	render() {
		return (
			<Page title="Admin GSM Tools" className="AdminGSMToolsPage" icon="fa-duotone fa-mobile-signal-out">
				<PacketSender connections={this.state.connections} onSelectAgentId={(id) => this.setState({ selectedAgentId: id })} />
				<RFLog connections={this.state.connections} selectedAgentId={this.state.selectedAgentId} />
			</Page>
		)
	}
}

class PacketSender extends React.Component {
	constructor(props) {
		super(props)
		this.formRef = React.createRef()
	}

	async send() {
		const formData = new FormData(this.formRef.current)

		let command = parseInt(formData.get('command'), 10)
		let hex = formData.get('payload')
		hex = hex.replace(/[^0-9a-fA-F]/g, '')

		let agentId = formData.get('gateway')
		const sendToAgents = []
		agentId = parseInt(agentId, 10)
		for (let con of API.connections) {
			if (con.id == agentId) {
				sendToAgents.push(con)
			}
		}

		for (let con of sendToAgents) {
			await API.call('agents.raw-command', {
				agentId: con.id,
				type: command,
				payloadHex: hex
			})
		}
	}

	render() {
		const commandList = []
		for (let name in RFCommand) {
			const code = RFCommand[name]
			if (typeof code === 'number') {
				const nameStr = name + ' (#' + code + ')'
				commandList.push({ code: RFCommand[name], name: nameStr })
			}
		}

		return (
			<div className="PacketSender">
				<H5>Command Sender</H5>
				<form ref={this.formRef}>
					<div className="Inputs">
						<FormGroup label="Gateway" labelFor="gateway">
							<select id="gateway" name="gateway" onChange={(e) => this.props.onSelectAgentId(parseInt(e.target.value, 10))}>
								<option value="0">Show All Traffic</option>
								{this.props.connections.map((con) => (
									<option value={con.id} key={`csocon${con.id}`}>
										{con.scanReport?.deviceId}
									</option>
								))}
							</select>
						</FormGroup>

						<FormGroup label="Command (DEC)" labelFor="command">
							<InputGroup id="command" name="command" placeholder="" />
						</FormGroup>

						<FormGroup label="Payload (HEX)" labelFor="payload">
							<InputGroup id="payload" name="payload" placeholder="" />
						</FormGroup>
					</div>
					<Button intent="primary" onClick={() => this.send()}>
						Send
					</Button>
				</form>
			</div>
		)
	}
}

class RFLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			packets: []
		}
		this.ac = 0
	}

	limitArraySize(arr, size) {
		if (arr.length > size) {
			arr.splice(size, arr.length - size)
		}
		return arr
	}

	componentDidMount() {
		API.addMessageListener(this, 'agents.raw-command', (cmd) => {
			cmd = { id: this.ac++, ...cmd }
			this.setState((prevState) => ({ packets: this.limitArraySize([cmd, ...prevState.packets], 250) }))
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	clear() {
		this.setState({ packets: [] })
	}

	render() {
		let displayPackets = this.state.packets
		if (this.props.selectedAgentId) {
			displayPackets = this.state.packets.filter((p) => p.agentId == this.props.selectedAgentId)
		}
		return (
			<div className="RFLog">
				<H5>
					Live Traffic <Button small minimal icon="trash" onClick={() => this.clear()} />
				</H5>
				<table className="bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped">
					<thead>
						<tr>
							<th width="100">Time</th>
							<th width="10">DIR</th>
							<th width="160">Gateway</th>
							<th width="70">Command</th>
							<th>Payload</th>
						</tr>
					</thead>
					<tbody>
						{displayPackets.map((p) => {
							let conInfo = '? ' + p.agentId + ' ?'
							for (let con of this.props.connections) {
								if (con.id == p.agentId) {
									p.connection = con
									if (con.scanReport) {
										conInfo = con.scanReport.deviceId
									}
								}
							}
							return (
								<tr key={p.id}>
									<td>{DateTime.fromMillis(p.time).toFormat('HH:mm:ss.SSS')}</td>
									<td>{p.direction}</td>
									<td>{conInfo}</td>
									<td>#{p.type}</td>
									<td>{p.payloadHex}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}
}
