/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const CloudServer: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="cloud-server" ref={ref}  {...props}>
            <path
                d={isLarge ? "M180 100H400V20H180V100zM240 60C240 48.954 231.046 40 220 40C208.954 40 200 48.954 200 60C200 71.046 208.954 80 220 80C231.046 80 240 71.046 240 60zM280 80C268.954 80 260 71.046 260 60C260 48.954 268.954 40 280 40H360C371.046 40 380 48.954 380 60C380 71.046 371.046 80 360 80H280zM295.68 299.568C297.12 299.784 298.56 300 300 300C348.352 300 388.724 265.627 397.996 220H180C168.9542 220 160 211.0456 160 200V100H80C35.8 100 0 135.8 0 180C0 217.4 25.6 248.4 60.2 257.2000000000001C60.2 257.7 60.15 258.15 60.1 258.6C60.05 259.05 60 259.5 60 260C60 326.2 113.8 380 180 380C232.4 380 276.6 346.2 292.8 299.2C293.76 299.28 294.7200000000001 299.424 295.68 299.568zM180 200H400V120H180V200zM240 160C240 148.954 231.046 140 220 140C208.954 140 200 148.954 200 160C200 171.046 208.954 180 220 180C231.046 180 240 171.046 240 160zM280 180C268.954 180 260 171.046 260 160C260 148.954 268.954 140 280 140H360C371.046 140 380 148.954 380 160C380 171.046 371.046 180 360 180H280z" : "M240 240C239.4 240 238.6 240 238 239.8C228.8 285.6 188.4 320 140 320C84.8 320 40 275.2 40 220C40 217.8 40.2 215.6 40.4 213.4C16.6 202.2 0 178 0 150C0 111.4 31.4 80 70 80H120V140C120 151.0458 128.9544 160 140 160H300C308.7320000000001 160 316.156 154.4042 318.884 146.6028C319.618 150.9588 320 155.4346 320 160C320 204.2 284.2000000000001 240 240 240zM140 140H300V80H140V140zM200 110C200 115.522 204.478 120 210 120H270C275.522 120 280 115.522 280 110C280 104.478 275.522 100 270 100H210C204.478 100 200 104.478 200 110zM180 110C180 104.478 175.5228 100 170 100C164.4772 100 160 104.478 160 110C160 115.522 164.4772 120 170 120C175.5228 120 180 115.522 180 110zM140 0V60H300V0H140zM200 30C200 35.522 204.478 40 210 40H270C275.522 40 280 35.522 280 30C280 24.478 275.522 20 270 20H210C204.478 20 200 24.478 200 30zM170 20C175.5228 20 180 24.478 180 30C180 35.522 175.5228 40 170 40C164.4772 40 160 35.522 160 30C160 24.478 164.4772 20 170 20z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
CloudServer.defaultProps = {
    size: IconSize.STANDARD,
};
CloudServer.displayName = `Blueprint5.Icon.CloudServer`;
export default CloudServer;
