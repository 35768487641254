/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Refresh: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="refresh" ref={ref}  {...props}>
            <path
                d={isLarge ? "M72.7208 327.2792C106.4774 361.0358 152.261 380 200 380C248.774 380 303.64 365.6654 340 330.5748V360C340 371.0456 348.954 380 360 380C371.046 380 380 371.0456 380 360V280C380 268.9544 371.046 260 360 260H280C268.954 260 260 268.9544 260 280C260 291.0456 268.954 300 280 300H313.998C287.926 326.4008 244.348 340 200 340C162.8698 340 127.2602 325.25 101.005 298.995C74.75 272.7398 60 237.1304 60 200C60 188.954 51.0456 180 40 180C28.9544 180 20 188.954 20 200C20 247.739 38.9642 293.5228 72.7208 327.2792zM327.2800000000001 72.72C293.522 38.964 247.738 20 200 20C151.2264 20 96.3604 34.334 60 69.426V40C60 28.954 51.0456 20 40 20C28.9544 20 20 28.954 20 40V120C20 131.046 28.9544 140 40 140H120C131.0458 140 140 131.046 140 120C140 108.954 131.0458 100 120 100H86.0012C112.0736 73.6 155.6518 60 200 60C237.13 60 272.74 74.75 298.9940000000001 101.006C325.25 127.26 340 162.87 340 200C340 211.0456 348.954 220 360 220C371.046 220 380 211.0456 380 200C380 152.26 361.036 106.478 327.2800000000001 72.72z" : "M160 260C104.7716 260 60 215.2284 60 160C60 148.9544 51.0456 140 40 140C28.9544 140 20 148.9544 20 160C20 237.3198 82.6802 300 160 300C194.383 300 232.382 291.6802 260 268.6506V280C260 291.0456 268.954 300 280 300C291.046 300 300 291.0456 300 280V220C300 208.9544 291.046 200 280 200H220C208.954 200 200 208.9544 200 220C200 231.0456 208.954 240 220 240H231.716C214.034 253.3168 188.34 260 160 260zM160 60C215.228 60 260 104.772 260 160C260 171.0456 268.954 180 280 180C291.046 180 300 171.0456 300 160C300 82.68 237.32 20 160 20C125.617 20 87.6184 28.32 60 51.35V40C60 28.954 51.0456 20 40 20C28.9544 20 20 28.954 20 40V100C20 111.046 28.9542 120 40 120H100C111.0458 120 120 111.046 120 100C120 88.954 111.0458 80 100 80H88.284C105.9654 66.684 131.66 60 160 60z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Refresh.defaultProps = {
    size: IconSize.STANDARD,
};
Refresh.displayName = `Blueprint5.Icon.Refresh`;
export default Refresh;
